import React, { useState, useEffect } from "react";
import { Fade } from "react-reveal";

import "./PreviousYearImpactSection.css";

const photos = ["/images/2024-impact-images/2024-image-1.jpg", "/images/2024-impact-images/2024-image-2.png",
    "/images/2024-impact-images/2024-image-3.png", "/images/2024-impact-images/2024-image-4.jpg",
    "/images/2024-impact-images/2024-image-5.png",
];

export default function PreviousYearImpactSection(props) {
    const [photoSlideIndex, setPhotoSlideIndex] = useState(0);

    const photoSlideInterval = 3000;
    const year = 2024;
    const foodRequests = 44;
    const utilitiesRequest = 46;
    const rentRequests = 30;
    const miscRequests = 38;

    const foodRequestsAmount = "$7,800.00";
    const utilitiesRequestsAmount = "$11,932.33";
    const rentRequestsAmount = "$20,401.84";
    const miscRequestsAmount = "$7,829.83";

    useEffect(() => {
        const imageIntervalID = setInterval(() => {
            setTimeout(() => {
                setPhotoSlideIndex((prevIndex) => (prevIndex + 1) % photos.length);
            }, 500);
        }, photoSlideInterval);

        // Clean up on dismount
        return () => {
            clearInterval(imageIntervalID);
        }
    }, [])


    return (
        <>
            <div className="previous-year-impact-section">
                <h1 className="header">Our {year} Impact</h1>
                <div className="previous-year-impact-content-container">
                    <div className="previous-year-impact-stats">
                        <ul className="previous-year-impact-stats-list">
                            <Fade>
                                <li className="previous-year-impact-stat-container">
                                    <h3 className="previous-year-impact-stat">{foodRequests} {props.width > 1200 ?  "Approved Applications for Food Assistance" : "Applications for Food Assistance"} </h3>
                                    <h3 className="previous-year-impact-stat">{foodRequestsAmount} in Assistance Given</h3>

                                </li>
                            </Fade>

                            <Fade>
                                <li className="previous-year-impact-stat-container">
                                    <h3 className="previous-year-impact-stat">{utilitiesRequest} {props.width > 1200 ? "Approved Applications for Utility Assistance"  : "Applications for Utility Assistance" }</h3>
                                    <h3 className="previous-year-impact-stat">{utilitiesRequestsAmount} in Assistance Given</h3>
                                </li>
                            </Fade>

                            <Fade>
                                <li className="previous-year-impact-stat-container">
                                    <h3 className="previous-year-impact-stat">{rentRequests} Applications for Rent Assistance</h3>
                                    <h3 className="previous-year-impact-stat">{rentRequestsAmount} in Assistance Given</h3>
                                </li>
                            </Fade>

                            <Fade>
                                <li className="previous-year-impact-stat-container">
                                    <h3 className="previous-year-impact-stat">{miscRequests} Applications for Misc Assistance</h3>
                                    <h3 className="previous-year-impact-stat">{miscRequestsAmount} in Assistance Given</h3>
                                </li>
                            </Fade>
                        </ul>
                        <Fade>
                            <h3 className="previous-year-impact-stats-end-line">Thank you for continuing to support us in our mission to help those in need!</h3>
                        </Fade>

                    </div>


                    <div className="previous-year-impact-image-container">
                        <Fade>
                            <img className="previous-year-impact-images" alt={'hearts unite to give support event photo #' + photoSlideIndex} src={photos[photoSlideIndex]} />
                        </Fade>
                    </div>
                </div>
            </div>
        </>
    )

};