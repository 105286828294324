import React, { useState } from "react";
import axios from "axios";

import { InputText } from 'primereact/inputtext';

import "./EventSignupStyles.css";
import EventQuestionContainer from "./EventQuestionContainer";

export default function EventSignup(props) {
    const userData = props.userData;
    const id = props.id;
    const [firstName, setFirstName] = useState(userData ? userData.firstName : "");
    const [lastName, setLastName] = useState(userData ? userData.lastName : "");
    const [email, setEmail] = useState(userData ? userData.email : "");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [questionAnswers, setQuestionAnswers] = useState([]);
    const questions = props.questions;



    const successMsg = "Thanks for Signing Up!";

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('email', email);
        formData.append('id', id);
        formData.append('questionAnswers', JSON.stringify(questionAnswers));
        axios.post("/api/attending-event", formData, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.data.status) {
                setSubmitted(true);
            } else {
                setError(true);
                setErrorMsg(res.data.message);
            }
        })
    }

    // State Handling

    const onFirstNameInput = (event) => {
        setFirstName(event.target.value);
        setError(false);
        setErrorMsg("");
    }

    const onLastNameInput = (event) => {
        setLastName(event.target.value);
        setError(false);
        setErrorMsg("");
    }

    const onEmailInput = (event) => {
        setEmail(event.target.value);
        setError(false);
        setErrorMsg("");
    }

    const updateQuestionAnswerAtIndex = (index, input) => {
            const newQuestionAnswers = [...questionAnswers];
            newQuestionAnswers[index] = input;
            setQuestionAnswers(newQuestionAnswers);
    }

    // Renders

    const renderSubmitButton = () => {
        if (firstName.length > 0 && lastName.length > 0 && email.length > 0) {
            return <button className="approved-button" onClick={handleSubmit}>Submit</button>
        }

        return <button className="denied-button" onClick={() => {
            setError(true);
            errorMsg("Please Fill Out All Fields!")
        }}>Submit</button>
    }


    return (
        <>
            <div className="event-signup">
                <h1 className="header">Signup for Event</h1>
                    <div className="event-signup-items">
                        <label for="fName">
                            First Name: {firstName.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <InputText id="fName" value={firstName} onChange={onFirstNameInput} />
                        </label>

                        <label for="lName">
                            Last Name: {lastName.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <InputText id="lName" value={lastName} onChange={onLastNameInput} />
                        </label>

                        <label for="email">
                            Email: {email.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <InputText id="email" value={email} onChange={onEmailInput} />
                        </label>

                        {questions.map((question, index) => {
                            console.log(question.type);
                            return (
                                <>
                                    <EventQuestionContainer
                                        questionText={question.question}
                                        type={question.type}
                                        updateValue={updateQuestionAnswerAtIndex}
                                        index={index}
                                    />
                                </>
                            )
                        })}

                    </div>

                    <h2 className={error ? "header error" : submitted ? "header success" : "header"}>
                        {error ? errorMsg : submitted ? successMsg : ""}
                    </h2>

                    {renderSubmitButton()}
            </div>
        </>
    )
}