import React, { useState } from 'react';
import GeneralStatistics from './GeneralStatistics';
import BleedingDisorderStatistics from './BleedingDisorderStatistics';
import './StatisticsControl.css';

const showGeneralStats = 1;
const showBleedingDisorderStats = 2;

// Accepts applications, events, reports, legacyApplications as prop inputs
// Everything else can be called in component for now
export default function StatisticsControl(props) {
    const [displaying, setDisplaying] = useState(null);

    const renderSelection = () => {
        switch (displaying) {
            case showGeneralStats: {
                return (
                    <>
                        <GeneralStatistics users={props.users}
                            applications={props.applications}
                            events={props.events} reports={props.reports} legacyApplications={props.legacyApplications} />
                    </>
                )
            }

            case showBleedingDisorderStats: {
                return (
                    <>
                        <BleedingDisorderStatistics applications={props.applications} />
                    </>
                )
            }
        }
    }

    return (
        <>
            <div className='stats-control-section'>
                <div className='stats-control'>
                    <h1 className='header'>Statistics</h1>
                    <div className='stats-control-nav'>
                        <button className='approved-button' onClick={() => {
                            setDisplaying(showGeneralStats);
                        }}>General</button>
                        <button className='approved-button' onClick={() => {
                            setDisplaying(showBleedingDisorderStats);
                        }}>Bleeding Disorder</button>
                    </div>
                </div>
                <div className='statistics-content-display'>
                    {renderSelection()}
                </div>
            </div>
        </>
    )
}