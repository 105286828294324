import React, { useState, useLayoutEffect } from "react";
import axios from 'axios';
import DashboardWelcome from "./DashboardWelcome";
import UserInfoBox from "./UserInfoBox";
import UserApplicationsContainer from "./UserApplicationsContainer";
import UserNewsletterPrompt from "./UserNewsletterPrompt";
import UserEventsDisplay from "./UserEventsDisplay";

// React-reveal for animations

import Fade from 'react-reveal/Fade';

// Custom Styling

import "./UserDashboardStyles.css";
import FullApplicationView from "./FullApplicationView";

// Showing States
const dashboard = 0;
const applicationView = 1;

function sortByDate(applications) {
    applications.sort(function (a, b) {
        var aa = a.issuedOn.split('/').reverse().join(),
            bb = b.issuedOn.split('/').reverse().join();
        return aa < bb ? -1 : (aa > bb ? 1 : 0);
    });
    return applications.reverse();
}

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function useFetchData() {
    axios.defaults.withCredentials = true;
    const [loading, setLoading] = useState(true);
    const [applications, setApplications] = useState(null);

    React.useEffect(() => {
        if (!applications) {
            setLoading(true);
            axios.get('/api/userapplications', { withCredentials: true })
                .then((res) => {
                    setApplications(sortByDate(res.data.applications));
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                })
        }

    }, []);
    return { loading, applications };
}

export default function UserDashboard(props) {
    // Width / Height
    const [width, height] = useWindowSize();

    // States
    const { loading, applications } = useFetchData();
    const [showing, setShowing] = useState(dashboard);
    const [activeApplication, setActiveApplication] = useState(null);

    // Events
    const changeShowingToApplication = (application) => {
        setActiveApplication(application);
        setShowing(applicationView);
    }

    const changeShowingToDashboard = () => {
        setShowing(dashboard);
        setActiveApplication(null);
    }

    // Renders

    const renderNewsletterPrompt = () => {
        if (!props.userData.newsletter) {
            return (
                <Fade>
                    <UserNewsletterPrompt width={width} />
                </Fade>

            )
        } else {
        }
    }

    const renderComponent = () => {
        switch (showing) {
            case dashboard: {
                return (
                    <>
                        <ul className="dashboard-row-container">
                            <li className="dashboard-item-infobox">
                                <Fade delay={150}>
                                    <UserInfoBox userData={props.userData} />
                                </Fade>
                            </li>

                            <li className="dashboard-item-applications-container">
                                <Fade>
                                    <UserApplicationsContainer applications={applications} loading={loading} changeShowingToApplication={changeShowingToApplication} width={width} />
                                </Fade>
                            </li>
                        </ul>

                        {renderNewsletterPrompt()}

                        <Fade>
                            <UserEventsDisplay />
                        </Fade>
                    </>
                )
            }

            case applicationView: {
                return <FullApplicationView application={activeApplication} changeShowingToDashboard={changeShowingToDashboard} />
            }

            default:
                break;

        }
    }

    return (
        <>
            <Fade>
                <DashboardWelcome fName={props.userData.firstName} />
            </Fade>

            {renderComponent()}

        </>
    )
}