import React, { useState, useLayoutEffect } from "react";
import axios from "axios";

// Custom Components
import ApplicationSubmitted from "./ApplicationSubmitted";
import AttachmentContainer from "../General/AttachmentContainer";
import FamilyMemberContainer from "./FamilyMemberContainer";

// Animation Imports
import { Fade } from "react-reveal";

// Form Imports

import Select from 'react-select';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from "primereact/inputtextarea";

// Select Options

import { GenderOptions } from "./DropdownOptions/GenderOptions";
import { RelationshipStatusOptions } from "./DropdownOptions/RelationshipStatusOptions";
import { StateOptions } from "./DropdownOptions/StateOptions";
import { BleedingDisorderRelationshipOptions } from "./DropdownOptions/BleedingDisorderRelationshipOptions";
import { BleedingDisorderTypeOptions } from "./DropdownOptions/BleedingDisorderTypeOptions";
import { BleedingDisorderSeverityOptions } from "./DropdownOptions/BleedingDisorderSeverityOptions";
import { MethodOptions } from "./DropdownOptions/MethodOptions";
import { TypeOptions } from "./DropdownOptions/TypeOptions";

// Attachment Errors

const maxAttachments = 10;

const attachmentSizeError = "Invalid File Size (Max Size: 8MB)!";
const attachmentTypeError = "Invalid File Type!";
const attachmentMaxError = "Maximum Uploads Reached!";

// File Types

const allowedTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/webp',
    'application/pdf',
    'application/rtf',
    'application/doc',
    'application/docx',
    'application/txt',
    'application/csv',
    'application/xls',
    'application/xlsx',
    'application/ppt',
    'application/pptx',
];

// Max File Size

// Currently 8MB, change the non 1024 number to modify up or down.
const maxSize = 8 * 1024 * 1024;

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const familyMemberTemplate = {
    name: "",
    relation: "",
    birthday: "",
};

export default function ApplicationForm(props) {
    // Width / Height
    const [width, height] = useWindowSize();

    // Owner
    const [owner, setOwner] = useState(null);

    // General Info Section
    const [firstName, setFirstName] = useState(props.userData ? props.userData.firstName : "");
    const [lastName, setLastName] = useState(props.userData ? props.userData.lastName : "");
    const [email, setEmail] = useState(props.userData ? props.userData.email : "");
    const [birthday, setBirthday] = useState("");
    const [phone, setPhone] = useState("");
    const [income, setIncome] = useState("");
    const [gender, setGender] = useState(null);
    const [relationshipStatus, setRelationshipStatus] = useState(null);
    const [isMinor, setIsMinor] = useState(null);

    // Family Section

    const [livesAlone, setLivesAlone] = useState(null);
    const [familyInformation, setFamilyInformation] = useState([]);

    // Address Section

    const [streetAddress, setStreetAddress] = useState("");
    const [streetAddress2, setStreetAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState(null);
    const [zip, setZip] = useState("");

    // Medical Info Section

    const [bleedingDisorder, setBleedingDisorder] = useState(null);
    const [bleedingDisorderRelationship, setBleedingDisorderRelationship] = useState("");
    const [bleedingDisorderType, setBleedingDisorderType] = useState("");
    const [bleedingDisorderSeverity, setBleedingDisorderSeverity] = useState("");
    const [HTC, setHTC] = useState("");

    // Application Info Section

    const [type, setType] = useState(null);
    const [method, setMethod] = useState(null);
    const [amount, setAmount] = useState("");
    const [details, setDetails] = useState("");
    const [otherApplication, setOtherApplication] = useState(null);
    const [otherLocations, setOtherLocations] = useState("");

    // Attachments

    const [attachments, setAttachments] = useState([]);
    const [attachmentError, setAttachmentError] = useState(false);
    const [attachmentErrorMessage, setAttachmentErrorMessage] = useState("");

    // Referral
    const [referred, setReferred] = useState(null);
    const [referrer, setReferrer] = useState("");
    const [permissions, setPermission] = useState(null);
    const [permissionError, setPermissionError] = useState(null);

    // Submission Handling

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [token, setToken] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    const symbols = [
        '<', '>', '&', '"', "'", '/', '\\', ';', '%', '$', '#', '{', '}', '[', ']',
        '(', ')', '=', '+', '-', '*', '`', '^', '|', '~', '!', '?', ':', '.', ',', '@'
    ];

    const filterNumbersAndSymbols = (input) => {
        for (var i = 0; i < input.length; i++) {
            if (numbers.includes(input[i]) || symbols.includes(input[i])) {
                return false;
            };
        };

        return true;
    };

    const verifyIsMinor = (birthday) => {
        const [month, day, year] = birthday.split('/');
        const birthDate = new Date(`${year}-${month}-${day}`);
        const today = new Date();
        var age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        };
        return age < 18;
    };

    const verifyIsNumber = (input) => {
        for (var i = 0; i < input.length; i++) {
            if (!numbers.includes(input[i])) {
                return false;
            };
        };
        return true;
    };

    // General Verifications

    const verifyFirstName = () => {
        if (firstName.length < 2 || firstName.length > 20) {
            return false;
        };

        return true;
    };

    const verifyLastName = () => {
        if (lastName.length < 2 || lastName.length > 20) {
            return false;
        };

        return true;
    };

    const verifyBirthday = () => {
        if (birthday.length === 10) {
            return true;
        };

        return false;
    };

    const verifyEmail = () => {
        if (email.includes('@') && email.includes('.') && email.length > 6 && email.length < 45) {
            return true;
        };

        return false;
    };

    const verifyPhone = () => {
        if (phone.length === 14) {
            return true;
        };

        return false;
    };

    const verifyIncome = () => {
        if (income.length !== 0 && income.length < 10 && verifyIsNumber(income)) {
            return true;
        };

        return false;
    };

    // Family member verification

    const verifyFamilyInformationEntry = (entry) => {
        if (entry.name.length < 2 || entry.relation === null || entry.birthday.length !== 10) {
            return false;
        };

        return true;
    };

    const verifyFamilyInformation = () => {
        for (const entry of familyInformation) {
            if (!verifyFamilyInformationEntry(entry)) {
                return false;
            };
        };

        return true;
    };

    // Address Verification

    const verifyStreetAddress = () => {
        if (streetAddress.length > 0 && streetAddress.length < 50) {
            return true;
        };

        return false;
    };

    const verifyCity = () => {
        if (city.length > 0 && city.length < 30) {
            return true;
        };

        return false;
    };

    const verifyZip = () => {
        if (zip.length === 5 && verifyIsNumber(zip)) {
            return true;
        };

        return false;
    };

    // Medical Info

    const verifyHTC = () => {
        if (HTC.length > 0 && HTC.length < 30) {
            return true;
        };

        return false;
    };

    // Info Section 

    const verifyAmount = () => {
        if (amount.length !== 0 && amount.length < 10 && verifyIsNumber(amount)) {
            return true;
        };

        return false;
    };

    const verifyDetails = () => {
        if (details.length > 0 && details.length <= 1500) {
            return true;
        };

        return false;
    };

    const verifyOtherLocations = () => {
        if (otherLocations.length > 0 && otherLocations.length < 150) {
            return true;
        };

        return false;
    };

    const verifyReferrer = () => {
        if (referrer.length > 0 && referrer.length < 150) {
            return true;
        };

        return false;
    };

    const addFamilyMember = () => {
        if (familyInformation.length < 11) {
            setFamilyInformation(prevFamilyInformation => [
                ...prevFamilyInformation,
                { ...familyMemberTemplate }
            ]);
        };
    };

    const updateSelectedFamilyMemberRelation = (index, newRelation) => {
        setFamilyInformation(prevFamilyInformation =>
            prevFamilyInformation.map((member, i) =>
                i === index ? { ...member, relation: newRelation } : member
            )
        );
    };

    const updateSelectedFamilyMemberName = (index, newName) => {
        setFamilyInformation(prevFamilyInformation =>
            prevFamilyInformation.map((member, i) =>
                i === index ? { ...member, name: newName } : member
            )
        );
    };

    const updateSelectedFamilyMemberBirthday = (index, newBirthday) => {
        setFamilyInformation(prevFamilyInformation =>
            prevFamilyInformation.map((member, i) =>
                i === index ? { ...member, birthday: newBirthday } : member
            )
        );
    }

    const removeSelectedFamilyMember = (index) => {
        setFamilyInformation(prevFamilyInformation =>
            prevFamilyInformation.filter((_, i) => i !== index)
        );
    };

    // Select Styling

    const selectStyles = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: state.isSelected ? "bold" : "normal",
            fontSize: width <= 1260 ? "1.2rem" : "1.4rem",
            color: "#2d3436",
            backgroundColor: "#fff",
            "&:hover": {
                backgroundColor: "#f0f0f0",
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? "#2d3436" : "#fff",
            margin: "12px 0 12px",
            height: width <= 1260 ? "48px" : "55px",
        }),
    };

    // Filter special characters


    const checkInputForSymbol = (input) => {
        return symbols.includes(input) ? true : false;
    }


    // Action Handling

    const handleSubmit = (event) => {
        event.preventDefault();
        if (verifyIsNumber(income) && verifyIsNumber(amount)) {
            const formData = new FormData();
            formData.append('firstName', firstName);
            formData.append('lastName', lastName);
            formData.append('email', email);
            formData.append('birthday', birthday);
            formData.append('phone', phone);
            formData.append('income', income);
            formData.append('gender', gender.value);
            formData.append('relationshipStatus', relationshipStatus.value);
            formData.append('streetAddress', streetAddress);
            formData.append('streetAddress2', streetAddress2);
            formData.append('city', city);
            formData.append('state', state.value);
            formData.append('ZIP', zip);
            formData.append('bleedingDisorder', bleedingDisorder);
            formData.append('bleedingDisorderRelationship', bleedingDisorderRelationship.value);
            formData.append('bleedingDisorderType', bleedingDisorderType.value);
            formData.append('bleedingDisorderSeverity', bleedingDisorderSeverity.value);
            formData.append('HTC', HTC);
            formData.append('method', method.value);
            formData.append('type', type.value);
            formData.append('amount', amount);
            formData.append('details', details);
            formData.append('otherApplication', otherApplication);
            formData.append('otherLocations', otherLocations);
            formData.append('referred', referred);
            formData.append('referrer', referrer);
            for (const attachment of attachments) {
                formData.append('attachments', attachment);
            }
            formData.append('isMinor', verifyIsMinor(birthday));
            formData.append('livesAlone', livesAlone);
            formData.append('familyData', JSON.stringify(familyInformation));
            axios.post('/api/applications', formData, {withCredentials: true,})
            .then((res) => {
                if (res.data.status) {
                    setToken(res.data.token);
                    setSubmitted(true);
                } else {
                    setError(true);
                    setErrorMessage(res.data.message);
                }
            })
        } else {
            setError(true);
            setErrorMessage("Both Income and Amount Requested Must Be Numbers!");
        }
    }

    // Attachments

    const onFileUpload = (event) => {
        const currentFile = event.target.files[0];
        // Check File Size
        if (currentFile.size <= maxSize) {
            // Check File Type
            if (allowedTypes.includes(currentFile.type)) {
                setAttachmentError(false);
                setAttachmentErrorMessage('');
                setAttachments(attachments.concat(event.target.files[0]));
                event.target.value = null;
            } else {
                setAttachmentError(true);
                setAttachmentErrorMessage(attachmentTypeError);
                event.target.value = null;
            }
        } else {
            setAttachmentError(true);
            setAttachmentErrorMessage(attachmentSizeError);
            event.target.value = null;
        }
    }

    const onFileDelete = (file) => {
        const newAttachments = attachments.filter(attachment => attachment !== file);
        setAttachments(newAttachments);
        if (attachmentErrorMessage === attachmentMaxError) {
            setAttachmentError(false);
            setAttachmentErrorMessage('');
        }
    }

    // General Info Section

    const renderGeneralCompleted = () => {
        if (gender !== null && relationshipStatus !== null) {
            if (verifyFirstName() && verifyLastName() && verifyEmail() && verifyBirthday() && verifyPhone()
                && verifyIncome() > 0 && gender.value.length > 0 && relationshipStatus.value.length > 0) {
                return <h2 className="completed section-status header">Section Completed!</h2>
            }
        }
        return <h2 className="incomplete section-status header">Section Incomplete</h2>
    }

    // Render General Section

    const renderGeneralSection = () => {
        if (width <= 480) {
            return (
                <>
                    <div className="application-section">
                        <h1 className="header">General Information</h1>
                        {renderGeneralCompleted()}

                        <div className="section-items">
                            <div className="section-item">
                                <label for="firstName">
                                    First Name: {!verifyFirstName() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                    <InputText id='firstName' value={firstName} onChange={(event) => {
                                        if (filterNumbersAndSymbols(event.target.value)) {
                                            setFirstName(event.target.value);
                                        }
                                    }} />
                                </label>
                            </div>

                            <div className="section-item">
                                <label for="lastName">
                                    Last Name: {!verifyLastName() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                    <InputText id='lastName' value={lastName} onChange={(event) => {
                                        if (filterNumbersAndSymbols(event.target.value)) {
                                            setLastName(event.target.value);
                                        }
                                    }} />
                                </label>
                            </div>

                            <div className="section-item">
                                <label for="email">
                                    Email: {verifyEmail() ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                    <InputText id='email' value={email} onChange={(event) => setEmail(event.target.value)} />
                                </label>
                            </div>

                            <div className="section-item">
                                <label for="birthday">
                                    Birthday: {verifyBirthday() ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                    <InputMask mask="99/99/9999" slotChar="mm/dd/yyyy" value={birthday} onChange={(event) => {
                                        setBirthday(event.target.value);
                                        if (event.target.value.length === 10) {
                                            setIsMinor(verifyIsMinor(event.target.value));
                                        }
                                    }} />
                                </label>
                            </div>

                            <div className="section-item">
                                <label>
                                    Phone: {!verifyPhone() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                    <InputMask mask="(999) 999-9999" slotChar="(___) ___-____" value={phone} onChange={(event) => setPhone(event.target.value)} />
                                </label>
                            </div>

                            <div className="section-item">
                                <label for="income">
                                    Monthly Income (Estimate): {!verifyIncome() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}

                                    <InputText id='income' value={income} onChange={(event) => setIncome(event.target.value)} />
                                </label>
                            </div>

                        </div>

                        <div className="section-item">
                            <label>
                                Gender: {gender ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={gender}
                                    onChange={(option) => setGender(option)} options={GenderOptions} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label>
                                Relationship Status: {relationshipStatus ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={relationshipStatus}
                                    onChange={(option) => setRelationshipStatus(option)} options={RelationshipStatusOptions} />
                            </label>
                        </div>

                    </div>
                </>
            )
        }

        return (
            <>
                <div className="application-section">
                    <h1 className="header">General Information</h1>
                    {renderGeneralCompleted()}

                    <div className="section-items">
                        <div className="section-item">
                            <label for="firstName">
                                First Name: {!verifyFirstName() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id='firstName' value={firstName} onChange={(event) => {
                                    if (filterNumbersAndSymbols(event.target.value)) {
                                        setFirstName(event.target.value);
                                    }
                                }} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label for="lastName">
                                Last Name: {!verifyLastName() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id='lastName' value={lastName} onChange={(event) => {
                                    if (filterNumbersAndSymbols(event.target.value)) {
                                        setLastName(event.target.value);
                                    }
                                }} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label for="email">
                                Email: {verifyEmail() ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <InputText id='email' value={email} onChange={(event) => setEmail(event.target.value)} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label for="birthday">
                                Birthday: {verifyBirthday() ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <InputMask mask="99/99/9999" slotChar="mm/dd/yyyy" value={birthday} onChange={(event) => {
                                    setBirthday(event.target.value);
                                    if (event.target.value.length === 10) {
                                        setIsMinor(verifyIsMinor(event.target.value));
                                    }
                                }} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label>
                                Phone: {!verifyPhone() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputMask mask="(999) 999-9999" slotChar="(___) ___-____" value={phone} onChange={(event) => setPhone(event.target.value)} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label for="income">
                                Monthly Income (Estimate): {!verifyIncome() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id='income' value={income} onChange={(event) => setIncome(event.target.value)} />
                            </label>
                        </div>

                    </div>

                    <div className="two-item-section">
                        <div className="section-item">
                            <label>
                                Gender: {gender ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={gender}
                                    onChange={(option) => setGender(option)} options={GenderOptions} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label>
                                Relationship Status: {relationshipStatus ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={relationshipStatus}
                                    onChange={(option) => setRelationshipStatus(option)} options={RelationshipStatusOptions} />
                            </label>
                        </div>
                    </div>

                </div>
            </>
        )
    }

    // Family Section

    const renderFamilyComplete = () => {
        if (!familyInformation.length > 0) {
            if (isMinor === false && livesAlone === true) {
                return <h2 className="completed section-status header">Section Completed!</h2>
            } else {
                return <h2 className="incomplete section-status header">Section Incomplete</h2>
            }
        }
        if (verifyFamilyInformation()) {
            return <h2 className="completed section-status header">Section Completed!</h2>
        } else {
            return <h2 className="incomplete section-status header">Section Incomplete</h2>
        }
    }

    const renderMinorInformation = () => {
        if (isMinor) {
            return (
                <>
                    <p>As a minor, you are required to put your parent / caretakers information before proceeding.</p>
                </>
            )
        }
    }

    const renderFamilyMemberInformation = () => {
        return (
            <>
                <ul className="family-member-information">
                    {familyInformation.map((familyMember, index) => {
                        return (
                            <li key={index}>
                                <FamilyMemberContainer updateName={updateSelectedFamilyMemberName}
                                    updateRelation={updateSelectedFamilyMemberRelation}
                                    updateBirthday={updateSelectedFamilyMemberBirthday}
                                    removeSelectedFamilyMember={removeSelectedFamilyMember}
                                    index={index}
                                />
                            </li>
                        )
                    })
                    }
                </ul>
            </>
        )
    }

    const renderFamilyMemberInformationAddSection = () => {
        if (livesAlone === false || isMinor === true) {
            return (
                <>
                    <div className="family-member-add-information-section">
                        <p>Please list all inviduals residing with you and any financial co-dependents.</p>
                        {renderMinorInformation()}
                        <p>(Limit 10)</p>
                        {renderFamilyMemberInformation()}
                        <button className={familyInformation.length < 11 ? 'approved-button' : 'denied-button'} onClick={addFamilyMember}>Add Family Member</button>
                    </div>
                </>
            )
        }
    }

    const renderFamilySection = () => {
        return (
            <>
                <div className="family-section application-section">
                    <h1 className="header">Family Information</h1>
                    {renderFamilyComplete()}
                    <h2>Do You Or the Person You're Applying For Live With Anyone or Have Any Financial Co-Dependents?</h2>
                    <div className="apply-buttons-container">
                        <button className={livesAlone === true ?
                            "apply-button declined no" : "apply-button no"}
                            onClick={() => {
                                setLivesAlone(true);
                            }}>No <i class="fa-solid fa-xmark"></i></button>

                        <button className={livesAlone === false ?
                            "apply-button approved yes" : "apply-button yes"}
                            onClick={() => {
                                setLivesAlone(false);
                            }}>Yes <i class="fa-solid fa-check"></i></button>
                    </div>

                    {renderFamilyMemberInformationAddSection()}
                </div>
            </>
        )
    }

    // Address Info Section

    const renderAddressComplete = () => {
        if (state != null) {
            if (verifyStreetAddress() && verifyCity() && state.value.length > 0 && verifyZip()) {
                return <h2 className="completed section-status header">Section Completed!</h2>
            }
        }
        return <h2 className="incomplete section-status header">Section Incomplete</h2>
    }

    const renderAddressSection = () => {
        if (width <= 850) {
            return (
                <>
                    <div className="application-section">
                        <h1 className="header">Address Information</h1>
                        {renderAddressComplete()}

                        <div className="section-items">

                            <div className="section-item">
                                <label for="streetAddress">
                                    Street Address: {!verifyStreetAddress() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                    <InputText id='streetAddress' value={streetAddress} onChange={(event) => setStreetAddress(event.target.value)} />
                                </label>
                            </div>

                            <div className="section-item">
                                <label for="streetAddress2">
                                    Street Address 2:
                                    <InputText id='streetAddress2' value={streetAddress2} onChange={(event) => setStreetAddress2(event.target.value)} />
                                </label>
                            </div>

                            <div className="section-item">
                                <label for="city">
                                    City: {!verifyCity() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                    <InputText id='city' value={city} onChange={(event) => setCity(event.target.value)} />
                                </label>
                            </div>

                            <div className="section-item">
                                <label for="zip">
                                    ZIP: {!verifyZip() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                    <InputText id='zip' value={zip} onChange={(event) => {
                                        if (verifyIsNumber(event.target.value)) {
                                            setZip(event.target.value);
                                        }
                                    }} />
                                </label>
                            </div>
                        </div>

                        <div className="section-item">
                            <label>
                                State: {state ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={state}
                                    onChange={(option) => setState(option)} options={StateOptions} />
                            </label>
                        </div>
                    </div>
                </>
            )
        }

        return (
            <>
                <div className="application-section">
                    <h1 className="header">Address Information</h1>
                    {renderAddressComplete()}

                    <div className="section-items">

                        <div className="section-item">
                            <label for="streetAddress">
                                Street Address: {!verifyStreetAddress() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}

                                <InputText id='streetAddress' value={streetAddress} onChange={(event) => setStreetAddress(event.target.value)} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label for="streetAddress2">
                                Street Address 2:

                                <InputText id='streetAddress2' value={streetAddress2} onChange={(event) => setStreetAddress2(event.target.value)} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label for="city">
                                City: {!verifyCity() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}

                                <InputText id='city' value={city} onChange={(event) => setCity(event.target.value)} />
                            </label>
                        </div>
                    </div>
                    <div className="two-item-section">
                        <div className="section-item">
                            <label>
                                State: {state ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={state}
                                    onChange={(option) => setState(option)} options={StateOptions} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label for="zip">
                                ZIP: {!verifyZip() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}

                                <InputText id='zip' value={zip} onChange={(event) => {
                                    if (verifyIsNumber(event.target.value)) {
                                        setZip(event.target.value);
                                    }
                                }} />
                            </label>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    // Medical Info Section

    const renderMedicalComplete = () => {
        if (bleedingDisorder === true) {
            if (bleedingDisorderRelationship != null && bleedingDisorderType != null && verifyHTC()) {
                return <h2 className="completed section-status header">Section Completed!</h2>
            } else {
                return <h2 className="incomplete section-status header">Section Incomplete</h2>
            }
        }

        if (bleedingDisorder === false) {
            return <h2 className="completed section-status header">Section Completed!</h2>
        }

        return <h2 className="incomplete section-status header">Section Incomplete</h2>
    }

    const renderMedicalSectionDetails = () => {
        if (bleedingDisorder === true) {
            if (width <= 736) {
                return (
                    <>
                        <div className="section-item">
                            <label>
                                Relationship to Bleeding Disorder: {bleedingDisorderRelationship.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={bleedingDisorderRelationship}
                                    onChange={(option) => setBleedingDisorderRelationship(option)} options={BleedingDisorderRelationshipOptions} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label>
                                Type: {bleedingDisorderType.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={bleedingDisorderType}
                                    onChange={(option) => setBleedingDisorderType(option)} options={BleedingDisorderTypeOptions} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label>
                                Severity: {bleedingDisorderSeverity.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={bleedingDisorderSeverity}
                                    onChange={(option) => setBleedingDisorderSeverity(option)} options={BleedingDisorderSeverityOptions} />
                            </label>
                        </div>

                        <label for="htc">
                            HTC: {!verifyHTC() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}

                            <InputText id='htc' value={HTC} onChange={(event) => setHTC(event.target.value)} />
                        </label>
                    </>
                )
            }
            return (
                <>
                    <div className="section-items">
                        <div className="section-item">
                            <label>
                                Relationship to Bleeding Disorder: {bleedingDisorderRelationship.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={bleedingDisorderRelationship}
                                    onChange={(option) => setBleedingDisorderRelationship(option)} options={BleedingDisorderRelationshipOptions} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label>
                                Type: {bleedingDisorderType.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={bleedingDisorderType}
                                    onChange={(option) => setBleedingDisorderType(option)} options={BleedingDisorderTypeOptions} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label>
                                Severity: {bleedingDisorderSeverity.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={bleedingDisorderSeverity}
                                    onChange={(option) => setBleedingDisorderSeverity(option)} options={BleedingDisorderSeverityOptions} />
                            </label>
                        </div>


                    </div>

                    <label for="htc">
                        HTC: {!verifyHTC() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}

                        <InputText id='htc' value={HTC} onChange={(event) => setHTC(event.target.value)} />
                    </label>
                </>

            )
        }
    }

    const renderMedicalSection = () => {
        return (
            <>
                <div className="application-section">
                    <h1 className="header">Medical Information</h1>
                    {renderMedicalComplete()}

                    <h2 className="header bleeding-disorder-question">Are You or the Person You're Applying for Affected by a Bleeding Disorder?</h2>
                    <div className="apply-buttons-container">
                        <button className={bleedingDisorder === false ? "apply-button declined no" : "apply-button no"}
                            onClick={() => {
                                setBleedingDisorder(false)
                            }}>No <i class="fa-solid fa-xmark"></i></button>

                        <button className={bleedingDisorder === true ? "apply-button approved yes" : "apply-button yes"}
                            onClick={() => { setBleedingDisorder(true) }}>Yes <i class="fa-solid fa-check"></i></button>
                    </div>

                    {renderMedicalSectionDetails()}
                </div>
            </>
        )
    }

    // Application Info Section

    const renderApplicationInfoCompleted = () => {
        if (type != null && method != null) {
            if (verifyAmount() &&
                verifyDetails() && otherApplication != null) {
                if (otherApplication === true) {
                    if (verifyOtherLocations()) {
                        return <h2 className="completed section-status header">Section Completed!</h2>
                    } else {
                        return <h2 className="incomplete section-status header">Section Incomplete</h2>
                    }
                }
                return <h2 className="completed section-status header">Section Completed!</h2>
            }
        }

        return <h2 className="incomplete section-status header">Section Incomplete</h2>

    }

    const renderOtherApplicationPrompt = () => {
        if (otherApplication) {
            return (
                <div className="section-item">
                    <label>
                        Where Else Have You Applied? (Please list all): {!verifyOtherLocations() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}

                        <InputText id='otherLocations' value={otherLocations} onChange={(event) => setOtherLocations(event.target.value)} />
                    </label>
                </div>

            )
        }
    }

    const renderApplicationInfoSection = () => {
        if (width <= 736) {
            return (
                <>
                    <div className="application-section">
                        <h1 className="header">Request Information</h1>
                        {renderApplicationInfoCompleted()}

                        <div className="section-item">
                            <label>
                                Type of Assistance: {type ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={type}
                                    onChange={(option) => setType(option)} options={TypeOptions} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label>
                                Method of Assistance: {method ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={method}
                                    onChange={(option) => setMethod(option)} options={MethodOptions} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label for="assistanceAmount">
                                Amount Requested (USD, Rounded): {!verifyAmount() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id="assistanceAmount" value={amount} onChange={(event) => { setAmount(event.target.value) }} />
                            </label>
                        </div>

                        <h2 className="header application-reason-header">Reason for Applying</h2>
                        <p>Explain in as much detail as necessary why you're applying for assistance,
                            include anything within reason that you feel will influence our decision (limit 1500 characters).</p>
                        <div>
                            <label for="assistanceDetails">
                                ({1500 - details.length} Characters Remaining):
                            </label>
                        </div>

                        <div>
                            <InputTextarea autoResize={true} rows={5} cols={30}
                                id="details" value={details} onChange={(event) => { setDetails(event.target.value) }} />
                        </div>

                        <h2>Are You Applying for Assistance From Other Sources?</h2>

                        <div className="apply-buttons-container">
                            <button className={otherApplication === false ? "apply-button declined no" : "apply-button no"}
                                onClick={() => {
                                    setOtherApplication(false)
                                }}>No <i class="fa-solid fa-xmark"></i></button>

                            <button className={otherApplication === true ? "apply-button approved yes" : "apply-button yes"}
                                onClick={() => { setOtherApplication(true) }}>Yes <i class="fa-solid fa-check"></i></button>
                        </div>

                        {renderOtherApplicationPrompt()}
                    </div>
                </>
            )
        }
        return (
            <>
                <div className="application-section">
                    <h1 className="header">Request Information</h1>
                    <p>Please remember to limit your request to 1 bill per application.</p>
                    {renderApplicationInfoCompleted()}

                    <div className="section-items">
                        <div className="section-item">
                            <label>
                                Type of Assistance: {type ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={type}
                                    onChange={(option) => setType(option)} options={TypeOptions} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label>
                                Method of Assistance: {method ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={method}
                                    onChange={(option) => setMethod(option)} options={MethodOptions} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label for="assistanceAmount">
                                Amount Requested (USD, Rounded): {!verifyAmount() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id="assistanceAmount" value={amount} onChange={(event) => { setAmount(event.target.value) }} />
                            </label>
                        </div>
                    </div>

                    <h2 className="header application-reason-header">Reason for Applying</h2>
                    <p>Explain in as much detail as necessary why you're applying for assistance,
                        include anything within reason that you feel will influence our decision (limit 1500 characters).</p>
                    <div>
                        <label for="assistanceDetails">
                            ({1500 - details.length} Characters Remaining):
                        </label>
                    </div>

                    <div>
                        <InputTextarea autoResize={true} rows={5} cols={30}
                            id="details" value={details} onChange={(event) => { setDetails(event.target.value) }} />
                    </div>

                    <h2>Are You Applying for Assistance From Other Sources?</h2>

                    <div className="apply-buttons-container">
                        <button className={otherApplication === false ? "apply-button declined no" : "apply-button no"}
                            onClick={() => {
                                setOtherApplication(false)
                            }}>No <i class="fa-solid fa-xmark"></i></button>

                        <button className={otherApplication === true ? "apply-button approved yes" : "apply-button yes"}
                            onClick={() => { setOtherApplication(true) }}>Yes <i class="fa-solid fa-check"></i></button>
                    </div>

                    {renderOtherApplicationPrompt()}
                </div>
            </>
        )
    }

    // Attachments Section

    const renderAttachments = () => {
        if (attachments.length > 0) {
            return (
                <>
                    <ul className="attachments-list">
                        {attachments.map((attachment, index) => {
                            return (
                                <li key={index}>
                                    <AttachmentContainer attachment={attachment}
                                        onFileDelete={onFileDelete}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                </>
            )
        } else {
            return (
                <>
                    <h3>Nothing Yet!</h3>
                </>
            )
        }
    }

    const renderAttachmentSection = () => {
        return (
            <>
                <div className="application-section">
                    <h1 className="header">Add Attachments</h1>
                    <p>Limit your request to 1 bill per application.</p>
                    <p>Applications with multiple bills attached will be denied.</p>
                    <p>Please ensure a fast response by attaching all relevant documents, including information for payment.</p>
                    <p>Applications which lack sufficient payment information will be automatically denied after 15 days.</p>
                    <p>To attach a file, click 'Upload Attachment' and select the desired file.</p>
                    <p>You can repeat this process to attach multiple files (Max 10 Uploads per Application).</p>
                    <p>If you accidentally upload a file, you can click 'Remove Attachment' to remove it.</p>
                    <h2 className="header uploaded-attachments-header">Currently Uploaded Attachments</h2>
                    {renderAttachments()}
                    <p>Accepted file types (Max Size: 8MB): </p>
                    <p>.png, .jpeg, .jpg, .webp, .pdf, .rtf, .doc, .docx, .txt, .csv, .xls, .xlsx, .ppt, .pptx</p>
                    <h2 className="error">{attachmentError ? attachmentErrorMessage : ''}</h2>
                    <div className="attachment-button-row">
                        <label onChange={onFileUpload} htmlFor="formId">
                            <input name="" type="file" id="formId" hidden />
                            <div className="attachment-button approved-button">
                                Upload File
                            </div>
                        </label>
                    </div>
                </div>
            </>
        )
    }

    // Referral Section

    const renderReferralSectionCompleted = () => {
        if (referred != null) {
            if (referred === true) {
                if (verifyReferrer() && permissions) {
                    return <h2 className="completed section-status header">Section Completed!</h2>
                } else {
                    return <h2 className="incomplete section-status header">Section Incomplete</h2>
                }
            }

            return <h2 className="completed section-status header">Section Completed!</h2>
        }

        return <h2 className="incomplete section-status header">Section Incomplete</h2>

    }

    const renderPermissionError = () => {
        if (permissionError) {
            return (
                <>
                    <Fade bottom>
                        <h2 className="error">We Cannot Accept Referral Applications Without Consent to Speak to the Referrer</h2>
                    </Fade>
                </>
            )
        }
    }

    const renderReferralSectionDetails = () => {
        if (referred === true) {
            return (
                <>
                    <div className="section-item">
                        <label for="referrer">
                            Who Referred You?: {referrer.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}

                            <InputText id='referrer' value={referrer} onChange={(event) => setReferrer(event.target.value)} />
                        </label>
                    </div>
                    <div className="section-item">
                        <h2 className="header">Do You Give Consent for This Person to Discuss Your Information with HUGS on Your Behalf?</h2>
                        <div className="apply-buttons-container">
                            <button className={permissions === false && permissions !== null ?
                                "apply-button declined no" : "apply-button no"}
                                onClick={() => {
                                    setPermission(false);
                                    setPermissionError(true);
                                }}>No <i class="fa-solid fa-xmark"></i></button>

                            <button className={permissions === true ? "apply-button approved yes" : "apply-button yes"}
                                onClick={() => {
                                    setPermission(true);
                                    setPermissionError(false);
                                }}>Yes <i class="fa-solid fa-check"></i></button>
                        </div>
                    </div>

                    {renderPermissionError()}
                </>
            )
        }
    }

    const renderReferralSection = () => {
        return (
            <>
                <div className="application-section">
                    <h1 className="header">Referral Information</h1>
                    {renderReferralSectionCompleted()}

                    <h2 className="header referred-header">Were You Referred to Us?</h2>
                    <div className="apply-buttons-container">
                        <button className={referred === false ? "apply-button declined no" : "apply-button no"}
                            onClick={() => {
                                setReferred(false)
                            }}>No <i class="fa-solid fa-xmark"></i></button>

                        <button className={referred === true ? "apply-button approved yes" : "apply-button yes"}
                            onClick={() => { setReferred(true) }}>Yes <i class="fa-solid fa-check"></i></button>
                    </div>

                    {renderReferralSectionDetails()}
                </div>
            </>
        )
    }

    const renderAttachmentReview = () => {
        if (attachments.length === 0) {
            return (
                <>
                    <h3>None</h3>
                    <p>We recommend adding any relevant attachments in order to speed up your assistance process!</p>
                </>
            )
        }

        return (
            <>
                <ul className="attachments-list">
                    {attachments.map((attachment, index) => {
                        return (
                            <li key={index}>
                                <h2>{attachment.name}</h2>
                            </li>
                        )
                    })}
                </ul>
            </>

        )
    }

    // Review Section

    const renderReviewSection = () => {
        return (
            <>
                <div className="application-section-review">
                    <h1 className="header">Review Your Application</h1>

                    <div className="review-container">
                        <div className="review-section">
                            <h2 className="header">General</h2>
                            {renderGeneralCompleted()}
                            <div className="review-items">
                                <div className="review-item">
                                    <h3>First Name: </h3> <p>{firstName ? firstName : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>Last Name: </h3> <p>{lastName ? lastName : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>Email: </h3> <p>{email ? email : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>Birthday: </h3> <p>{birthday ? birthday : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>Phone: </h3> <p>{phone ? phone : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>Income: </h3> <p>{"$" + income + "/mo" ? "$" + income + "/mo" : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>Gender: </h3> <p>{gender ? gender.value : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>Relationship Status: </h3> <p>{relationshipStatus ? relationshipStatus.value : "N/A"}</p>
                                </div>

                            </div>


                        </div>

                        <div className="review-section">
                            <h2 className="header">Address Information</h2>
                            {renderAddressComplete()}

                            <div className="review-items">
                                <div className="review-item">
                                    <h3>Street Address: </h3> <p>{streetAddress ? streetAddress : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>Street Address 2: </h3> <p>{streetAddress2 ? streetAddress2 : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>City: </h3> <p>{city ? city : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>State: </h3> <p>{state ? state.value : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>ZIP: </h3> <p>{zip ? zip : "N/A"}</p>
                                </div>
                            </div>

                        </div>

                        <div className="review-section">
                            <h2 className="header">Medical Information</h2>
                            {renderMedicalComplete()}

                            <div className="review-items">
                                <div className="review-item">
                                    <h3>Bleeding Disorder: </h3> <p>{bleedingDisorder ? "Yes" : "No"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>Relationship to Bleeding Disorder: </h3> <p>{bleedingDisorder ? bleedingDisorderRelationship.value : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>Type: </h3> <p>{bleedingDisorder ? bleedingDisorderType.value : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>Severity: </h3> <p>{bleedingDisorder ? bleedingDisorderSeverity.value : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>HTC: </h3> <p>{bleedingDisorder ? HTC : "N/A"}</p>
                                </div>
                            </div>
                        </div>

                        <div className="review-section">
                            <h2 className="header">Request Information</h2>
                            {renderApplicationInfoCompleted()}

                            <div className="review-items">
                                <div className="review-item">
                                    <h3>Type of Assistance: </h3> <p>{type ? type.value : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>Method of Assistance: </h3> <p>{method ? method.value : "N/A"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>Amount Requested (USD, Rounded): </h3> <p>{amount ? amount : "N/A"}</p>
                                </div>
                            </div>

                            <h3>Reason for Applying:</h3>
                            <p>{details}</p>
                        </div>

                        <div className="review-section">
                            <h2 className="header">Attachments</h2>
                            {renderAttachmentReview()}

                        </div>

                        <div className="review-section">
                            <h2 className="header">Referral Information</h2>
                            {renderReferralSectionCompleted()}

                            <div className="review-items">
                                <div className="review-item">
                                    <h3>Referred: </h3> <p>{referred ? "Yes" : "No"}</p>
                                </div>

                                <div className="review-item">
                                    <h3>Who Referred You?: </h3> <p>{referred ? referrer : "N/A"}</p>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }

    // Error Message

    const renderErrorMessage = () => {
        if (error) {
            return <h2 className="error">{errorMessage}</h2>
        }
    }

    // Submit Section

    const renderApplicationCompleted = () => {
        // General
        if (gender != null && relationshipStatus != null) {
            if (verifyFirstName() && verifyLastName() && verifyEmail() && verifyBirthday() && verifyPhone()
                && verifyIncome() && gender.value.length > 0 && relationshipStatus.value.length > 0) {
                // Family Information
                if (!familyInformation.length > 0 && isMinor === false && livesAlone === true || verifyFamilyInformation()) {
                    // Address
                    if (state != null) {
                        if (verifyStreetAddress() && verifyCity() && state.value.length > 0 && verifyZip()) {
                            // Medical
                            if (bleedingDisorder === true && bleedingDisorderRelationship != null && bleedingDisorderType != null && verifyHTC() || bleedingDisorder === false) {
                                // Application Info
                                if (type != null && method != null) {
                                    if (type.value.length > 0 && method.value.length > 0 && verifyAmount() &&
                                        verifyDetails() && otherApplication != null) {
                                        if (otherApplication === true && verifyOtherLocations() || otherApplication === false) {
                                            // Referral Section
                                            if (referred != null) {
                                                if (referred === true && verifyReferrer() && permissions || referred === false) {
                                                    return (
                                                        <div className="application-section submit-section">
                                                            <h1 className="header">Submit Your Application</h1>
                                                            <h2 className="completed section-status header">Application Completed!</h2>
                                                            <div className="submit-section-text">
                                                                <p>By submitting this form, I certify that the information I have provided in the above is true and correct.</p>
                                                                <p>
                                                                    I consent to the release of pertinent information contained in this application to Hearts Unite to Give Support Inc.,
                                                                    other social service agencies which distribute emergency financial assistance,
                                                                    the company or individual to receive funds as necessary to complete the services to my household, or to provide statistics on emergency assistance,
                                                                    or as a guard against duplicate assistance.
                                                                    I also consent to release of patient information to the federal government and those utility companies which require documentation of recipient's funds. </p>
                                                            </div>
                                                            {renderErrorMessage()}
                                                            <button className="apply-submit-button approved-button" onClick={handleSubmit}>Submit Application</button>
                                                        </div>
                                                    )
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return (
            <div className="application-section submit-section">
                <h1 className="header">Submit Your Application</h1>
                <h2 className="incomplete section-status header">Application Incomplete</h2>

                <div className="submit-section-text">
                    <p>By submitting this form, I certify that the information I have provided in the above is true and correct.</p>
                    <p>
                        I consent to the release of pertinent information contained in this application to Hearts Unite to Give Support Inc.,
                        other social service agencies which distribute emergency financial assistance,
                        the company or individual to receive funds as necessary to complete the services to my household, or to provide statistics on emergency assistance,
                        or as a guard against duplicate assistance.
                        I also consent to release of patient information to the federal government and those utility companies which require documentation of recipient's funds. </p>
                </div>
                {renderErrorMessage()}
                <button className="apply-submit-button denied-button" onClick={() => {
                    setError(true);
                    setErrorMessage("Please Complete Application Before Submitting!");
                }}>Unable to Submit</button>
            </div>
        )
    }

    const renderSubmitSection = () => {
        return (
            <>
                {renderApplicationCompleted()}
            </>
        )
    }

    if (submitted) {
        return (
            <>
                <ApplicationSubmitted guest={props.guest} name={firstName} token={token} email={email}
                />
            </>
        )
    }

    return (
        <>

            <div className="application-form">
                <Fade>
                    {renderGeneralSection()}
                </Fade>

                <Fade>
                    {renderFamilySection()}
                </Fade>

                <Fade>
                    {renderAddressSection()}
                </Fade>

                <Fade>
                    {renderMedicalSection()}
                </Fade>

                <Fade>
                    {renderApplicationInfoSection()}
                </Fade>

                <Fade>
                    {renderAttachmentSection()}
                </Fade>

                <Fade>
                    {renderReferralSection()}
                </Fade>

                <Fade>
                    {renderReviewSection()}
                </Fade>

                <Fade>
                    {renderSubmitSection()}
                </Fade>
            </div>
        </>
    )
}