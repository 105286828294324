import React, { useState, useEffect } from "react";
import axios from 'axios';

import BleedingDisorderStatisticsSortingAlgorithm from "../../../../data/BleedingDisorderStatisticsSortingAlgorithm";

// Styles
import "./BleedingDisorderStatistics.css";

// Display values
const nothingShowing = 0;
const hemoAShowing = 1;
const hemoBShowing = 2;
const hemoCShowing = 3;
const VWDShowing = 4;
const fac1Showing = 5;
const fac2Showing = 6;
const fac5Showing = 7;
const fac7Showing = 8;
const fac10Showing = 9;
const fac12Showing = 10;
const fac13Showing = 11;
const allShowing = 12;

// Takes applications as an input for processing if needed
export default function BleedingDisorderStatistics(props) {
    const applications = props.applications;
    const [statsCalculated, setStatsCalculated] = useState(false);
    const [bleedingDisorderStats, setBleedingDisorderStats] = useState(null);

    // Access
    const [applicationAccess, setApplicationAccess] = useState(null);
    const [displaying, setDisplaying] = useState(nothingShowing);


    const updateBleedingDisorderStats = () => {
        const updatedStats = BleedingDisorderStatisticsSortingAlgorithm(applications);
        setBleedingDisorderStats(updatedStats);
        const formData = new FormData();
        formData.append('updatedStats', JSON.stringify(updatedStats));
        axios.post('/api/update-bleeding-disorder-statistics', formData, { withCredientials: true })
            .then((res) => {

            })
    }

    useEffect(() => {
        axios.defaults.withCredentials = true;
        if (!statsCalculated) {
            axios.get('/api/last-bleeding-disorder-statistics-entry', { withCredentials: true })
                .then((res) => {
                    if (res.data.existingEntry) {
                        setBleedingDisorderStats(res.data.statistics);
                    } else {
                        updateBleedingDisorderStats();
                    }
                })
        }
    }, []);

    if (bleedingDisorderStats === null) {
        return (
            <>
                <h1 className="header bleeding-disorder-stats-loading-text">Loading...</h1>
            </>
        )
    }

    return (
        <>
            <h1 className="header">Bleeding Disorder Statistics</h1>
            <div className="bleeding-disorder-statistics-nav">
                <button className="bleeding-disorder-statistics-nav-category-button approved-button" onClick={() => {
                    setDisplaying(allShowing);
                }}>
                    All
                </button>

                <button className={bleedingDisorderStats.hemoAPatients > 0 || bleedingDisorderStats.hemoACaretakers > 0 ||
                    bleedingDisorderStats.hemoAFamilyMembers > 0 ? "bleeding-disorder-statistics-nav-category-button approved-button" :
                    "bleeding-disorder-statistics-nav-category-button denied-button"} onClick={() => {
                        if (bleedingDisorderStats.hemoAPatients > 0 || bleedingDisorderStats.hemoACaretakers > 0 ||
                            bleedingDisorderStats.hemoAFamilyMembers > 0) {
                            setDisplaying(hemoAShowing);
                        }
                    }}>
                    A
                </button>

                <button className={bleedingDisorderStats.hemoBPatients > 0 || bleedingDisorderStats.hemoBCaretakers > 0 ||
                    bleedingDisorderStats.hemoBFamilyMembers > 0 ? "bleeding-disorder-statistics-nav-category-button approved-button" :
                    "bleeding-disorder-statistics-nav-category-button denied-button"} onClick={() => {
                        if (bleedingDisorderStats.hemoBPatients > 0 || bleedingDisorderStats.hemoBCaretakers > 0 ||
                            bleedingDisorderStats.hemoBFamilyMembers > 0) {
                            setDisplaying(hemoBShowing);
                        }
                    }}>
                    B
                </button>

                <button className={bleedingDisorderStats.hemoCPatients > 0 || bleedingDisorderStats.hemoCCaretakers > 0 ||
                    bleedingDisorderStats.hemoCFamilyMembers > 0 ? "bleeding-disorder-statistics-nav-category-button approved-button" :
                    "bleeding-disorder-statistics-nav-category-button denied-button"} onClick={() => {
                        if (bleedingDisorderStats.hemoCPatients > 0 || bleedingDisorderStats.hemoCCaretakers > 0 ||
                            bleedingDisorderStats.hemoCFamilyMembers > 0) {
                            setDisplaying(hemoCShowing);
                        }
                    }}>
                    C
                </button>

                <button className={bleedingDisorderStats.VWDPatients > 0 || bleedingDisorderStats.VWDCaretakers > 0 ||
                    bleedingDisorderStats.VWDFamilyMembers > 0 ? "bleeding-disorder-statistics-nav-category-button approved-button" :
                    "bleeding-disorder-statistics-nav-category-button denied-button"} onClick={() => {
                        if (bleedingDisorderStats.VWDPatients > 0 || bleedingDisorderStats.VWDCaretakers > 0 ||
                            bleedingDisorderStats.VWDFamilyMembers > 0) {
                            setDisplaying(VWDShowing);
                        }
                    }}>
                    VWD
                </button>

                <button className={bleedingDisorderStats.fac1Patients > 0 || bleedingDisorderStats.fac1Caretakers > 0 ||
                    bleedingDisorderStats.fac1FamilyMembers > 0 ? "bleeding-disorder-statistics-nav-category-button approved-button" :
                    "bleeding-disorder-statistics-nav-category-button denied-button"} onClick={() => {
                        if (bleedingDisorderStats.fac1Patients > 0 || bleedingDisorderStats.fac1Caretakers > 0 ||
                            bleedingDisorderStats.fac1FamilyMembers > 0) {
                            setDisplaying(fac1Showing);
                        }
                    }}>
                    1
                </button>

                <button className={bleedingDisorderStats.fac2Patients > 0 || bleedingDisorderStats.fac2Caretakers > 0 ||
                    bleedingDisorderStats.fac2FamilyMembers > 0 ? "bleeding-disorder-statistics-nav-category-button approved-button" :
                    "bleeding-disorder-statistics-nav-category-button denied-button"} onClick={() => {
                        if (bleedingDisorderStats.fac2Patients > 0 || bleedingDisorderStats.fac2Caretakers > 0 ||
                            bleedingDisorderStats.fac2FamilyMembers > 0) {
                            setDisplaying(fac2Showing);
                        }
                    }}>
                    2
                </button>

                <button className={bleedingDisorderStats.fac5Patients > 0 || bleedingDisorderStats.fac5Caretakers > 0 ||
                    bleedingDisorderStats.fac5FamilyMembers > 0 ? "bleeding-disorder-statistics-nav-category-button approved-button" :
                    "bleeding-disorder-statistics-nav-category-button denied-button"} onClick={() => {
                        if (bleedingDisorderStats.fac5Patients > 0 || bleedingDisorderStats.fac5Caretakers > 0 ||
                            bleedingDisorderStats.fac5FamilyMembers > 0) {
                            setDisplaying(fac5Showing);
                        }
                    }}>
                    5
                </button>

                <button className={bleedingDisorderStats.fac7Patients > 0 || bleedingDisorderStats.fac7Caretakers > 0 ||
                    bleedingDisorderStats.fac7FamilyMembers > 0 ? "bleeding-disorder-statistics-nav-category-button approved-button" :
                    "bleeding-disorder-statistics-nav-category-button denied-button"} onClick={() => {
                        if (bleedingDisorderStats.fac7Patients > 0 || bleedingDisorderStats.fac7Caretakers > 0 ||
                            bleedingDisorderStats.fac7FamilyMembers > 0) {
                            setDisplaying(fac7Showing);
                        }
                    }}>
                    7
                </button>

                <button className={bleedingDisorderStats.fac10Patients > 0 || bleedingDisorderStats.fac10Caretakers > 0 ||
                    bleedingDisorderStats.fac10FamilyMembers > 0 ? "bleeding-disorder-statistics-nav-category-button approved-button" :
                    "bleeding-disorder-statistics-nav-category-button denied-button"} onClick={() => {
                        if (bleedingDisorderStats.fac10Patients > 0 || bleedingDisorderStats.fac10Caretakers > 0 ||
                            bleedingDisorderStats.fac10FamilyMembers > 0) {
                            setDisplaying(fac10Showing);
                        }
                    }}>
                    10
                </button>

                <button className={bleedingDisorderStats.fac12Patients > 0 || bleedingDisorderStats.fac12Caretakers > 0 ||
                    bleedingDisorderStats.fac12FamilyMembers > 0 ? "bleeding-disorder-statistics-nav-category-button approved-button" :
                    "bleeding-disorder-statistics-nav-category-button denied-button"} onClick={() => {
                        if (bleedingDisorderStats.fac12Patients > 0 || bleedingDisorderStats.fac12Caretakers > 0 ||
                            bleedingDisorderStats.fac12FamilyMembers > 0) {
                            setDisplaying(fac12Showing);
                        }
                    }}>
                    12
                </button>

                <button className={bleedingDisorderStats.fac13Patients > 0 || bleedingDisorderStats.fac13Caretakers > 0 ||
                    bleedingDisorderStats.fac13FamilyMembers > 0 ? "bleeding-disorder-statistics-nav-category-button approved-button" :
                    "bleeding-disorder-statistics-nav-category-button denied-button"} onClick={() => {
                        if (bleedingDisorderStats.fac13Patients > 0 || bleedingDisorderStats.fac13Caretakers > 0 ||
                            bleedingDisorderStats.fac13FamilyMembers > 0) {
                            setDisplaying(fac13Showing);
                        }
                    }}>
                    13
                </button>
            </div>
        </>
    )


}