import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import "./EventQuestionContainer.css";

const trueFalse = 0;
const textQuestion = 1;

export default function EventQuestionContainer(props) {
    const [answer, setAnswer] = useState(null);
    const questionType = props.type;

    const renderQuestionField = () => {
        if (questionType === textQuestion) {
            return (
                <InputText id='answer' value={answer} onChange={(event) => {
                    // Update local state
                    setAnswer(event.target.value);
                    // Update parent state
                    props.updateValue(props.index, event.target.value);
                }} />
            )
        }

        if (questionType === trueFalse)
        return (
            <>
                <div className="apply-buttons-container">
                    <button className={answer === false ?
                        "apply-button declined no" : "apply-button no"}
                        onClick={() => {
                            // Update local state
                            setAnswer(false);
                            // Update parent state
                            props.updateValue(props.index, false);
                        }}>No <i class="fa-solid fa-xmark"></i></button>

                    <button className={answer === true ?
                        "apply-button approved yes" : "apply-button yes"}
                        onClick={() => {
                            setAnswer(true);
                            // Update parent state
                            props.updateValue(props.index, true);
                        }}>Yes <i class="fa-solid fa-check"></i></button>
                </div>
            </>
        )
    }


    return (
        <>
            <div className="event-question-container">
                <h2 className="event-question-text">{props.questionText}</h2>
                {renderQuestionField()}
            </div>
        </>
    )

}