// Takes applications as an input and sorts them into data for use in BleedingDisorderStatistics component

// Hemophilia Types for processing in bleeding disorder statistic calculating function
const hemoASortValue = 1;
const hemoBSortValue = 2;
const hemoCSortValue = 3;
const VWDSortValue = 4;
const fac1SortValue = 5;
const fac2SortValue = 6;
const fac5SortValue = 7;
const fac7SortValue = 8;
const fac10SortValue = 9;
const fac12SortValue = 10;
const fac13SortValue = 11;

// Takes bleeding disorder type as an input, returns corresponding number for processing in 
// parent function
function categorizeBleedingDisorderType(type) {
    switch (type) {
        case "Hemophilia A":
            return hemoASortValue;

        case "Hemophilia B":
            return hemoBSortValue;

        case "Hemophilia C":
            return hemoCSortValue;

        case "Von Willebrand Disease":
            return VWDSortValue;

        case "Factor 1 Deficiency":
            return fac1SortValue;

        case "Factor 2 Deficiency":
            return fac2SortValue;

        case "Factor 5 Deficiency":
            return fac5SortValue;

        case "Factor 7 Deficiency":
            return fac7SortValue;

        case "Factor 10 Deficiency":
            return fac10SortValue;

        case "Factor 12 Deficiency":
            return fac12SortValue;

        case "Factor 13 Deficiency":
            return fac13SortValue;

        default:
            break;
    }
}

// Bleeding disorder severity values for sorting
const severeSortValue = 1;
const mildSortValue = 2;
const moderateSortValue = 3;

function categorizeBleedingDisorderSeverity(severity) {
    switch (severity) {
        case "Severe":
            return severeSortValue;

        case "Mild":
            return mildSortValue;

        case "Moderate":
            return moderateSortValue;

        default:
            break;
    }
}

// Bleeding disorder relationship values for sorting
const selfSortValue = 1;
const careTakerSortValue = 2;
const familyMemberSortValue = 3;

function categorizeBleedingDisorderRelationship(relationship) {
    switch (relationship) {
        case "Self":
            return selfSortValue;

        case "Care Taker of Patient":
            return careTakerSortValue;

        case "Family Member of Patient":
            return familyMemberSortValue;

            default:
                break;
    }
}

export default function BleedingDisorderStatisticsSortingAlgorithm(apps) {
    // Hemophilia A
    var hemoAPatients = 0;
    var hemoAPatientContacts = [];

    var hemoACaretakers = 0;
    var hemoACaretakerContacts = [];

    var hemoAFamilyMembers = 0;
    var hemoAFamilyMemberContacts = [];

    // Hemophilia A Severe
    var hemoASeverePatients = 0;
    var hemoASeverePatientContacts = [];

    var hemoASevereCaretakers = 0;
    var hemoASevereCaretakerContacts = [];

    var hemoASevereFamilyMembers = 0;
    var hemoASevereFamilyMemberContacts = [];

    // Hemophilia A Mild
    var hemoAMildPatients = 0;
    var hemoAMildPatientContacts = [];

    var hemoAMildCaretakers = 0;
    var hemoAMildCaretakerContacts = [];

    var hemoAMildFamilyMembers = 0;
    var hemoAMildFamilyMemberContacts = [];

    // Hemophilia A Moderate
    var hemoAModeratePatients = 0;
    var hemoAModeratePatientContacts = [];

    var hemoAModerateCaretakers = 0;
    var hemoAModerateCaretakerContacts = [];

    var hemoAModerateFamilyMembers = 0;
    var hemoAModerateFamilyMemberContacts = [];

    // Hemophilia B

    var hemoBPatients = 0;
    var hemoBPatientContacts = [];

    var hemoBCaretakers = 0;
    var hemoBCaretakerContacts = [];

    var hemoBFamilyMembers = 0;
    var hemoBFamilyMemberContacts = [];

    // Hemophilia B Severe
    var hemoBSeverePatients = 0;
    var hemoBSeverePatientContacts = [];

    var hemoBSevereCaretakers = 0;
    var hemoBSevereCaretakerContacts = [];

    var hemoBSevereFamilyMembers = 0;
    var hemoBSevereFamilyMemberContacts = [];

    // Hemophilia B Mild
    var hemoBMildPatients = 0;
    var hemoBMildPatientContacts = [];

    var hemoBMildCaretakers = 0;
    var hemoBMildCaretakerContacts = [];

    var hemoBMildFamilyMembers = 0;
    var hemoBMildFamilyMemberContacts = [];

    // Hemophilia B Moderate
    var hemoBModeratePatients = 0;
    var hemoBModeratePatientContacts = [];

    var hemoBModerateCaretakers = 0;
    var hemoBModerateCaretakerContacts = [];

    var hemoBModerateFamilyMembers = 0;
    var hemoBModerateFamilyMemberContacts = [];

    // Hemophilia C
    var hemoCPatients = 0;
    var hemoCPatientContacts = [];

    var hemoCCaretakers = 0;
    var hemoCCaretakerContacts = [];

    var hemoCFamilyMembers = 0;
    var hemoCFamilyMemberContacts = [];

    // Hemophilia C Severe
    var hemoCSeverePatients = 0;
    var hemoCSeverePatientContacts = [];

    var hemoCSevereCaretakers = 0;
    var hemoCSevereCaretakerContacts = [];

    var hemoCSevereFamilyMembers = 0;
    var hemoCSevereFamilyMemberContacts = [];

    // Hemophilia C Mild
    var hemoCMildPatients = 0;
    var hemoCMildPatientContacts = [];

    var hemoCMildCaretakers = 0;
    var hemoCMildCaretakerContacts = [];

    var hemoCMildFamilyMembers = 0;
    var hemoCMildFamilyMemberContacts = [];

    // Hemophilia C Moderate
    var hemoCModeratePatients = 0;
    var hemoCModeratePatientContacts = [];

    var hemoCModerateCaretakers = 0;
    var hemoCModerateCaretakerContacts = [];

    var hemoCModerateFamilyMembers = 0;
    var hemoCModerateFamilyMemberContacts = [];

    // VWD Patients
    var VWDPatients = 0;
    var VWDPatientContacts = [];

    var VWDCaretakers = 0;
    var VWDCaretakerContacts = [];

    var VWDFamilyMembers = 0;
    var VWDFamilyMemberContacts = [];

    // VWD Severe
    var VWDSeverePatients = 0;
    var VWDSeverePatientContacts = [];

    var VWDSevereCaretakers = 0;
    var VWDSevereCaretakerContacts = [];

    var VWDSevereFamilyMembers = 0;
    var VWDSevereFamilyMemberContacts = [];

    // VWD Mild
    var VWDMildPatients = 0;
    var VWDMildPatientContacts = [];

    var VWDMildCaretakers = 0;
    var VWDMildCaretakerContacts = [];

    var VWDMildFamilyMembers = 0;
    var VWDMildFamilyMemberContacts = [];

    // VWD Moderate
    var VWDModeratePatients = 0;
    var VWDModeratePatientContacts = [];

    var VWDModerateCaretakers = 0;
    var VWDModerateCaretakerContacts = [];

    var VWDModerateFamilyMembers = 0;
    var VWDModerateFamilyMemberContacts = [];

    // Factor 1 Deficiency
    var fac1Patients = 0;
    var fac1PatientContacts = [];

    var fac1Caretakers = 0;
    var fac1CaretakerContacts = [];

    var fac1FamilyMembers = 0;
    var fac1FamilyMemberContacts = [];

    // Factor 1 Deficiency Severe
    var fac1SeverePatients = 0;
    var fac1SeverePatientContacts = [];

    var fac1SevereCaretakers = 0;
    var fac1SevereCaretakerContacts = [];

    var fac1SevereFamilyMembers = 0;
    var fac1SevereFamilyMemberContacts = [];

    // Factor 1 Deficiency Mild
    var fac1MildPatients = 0;
    var fac1MildPatientContacts = [];

    var fac1MildCaretakers = 0;
    var fac1MildCaretakerContacts = [];

    var fac1MildFamilyMembers = 0;
    var fac1MildFamilyMemberContacts = [];

    // Factor 1 Deficiency Moderate
    var fac1ModeratePatients = 0;
    var fac1ModeratePatientContacts = [];

    var fac1ModerateCaretakers = 0;
    var fac1ModerateCaretakerContacts = [];

    var fac1ModerateFamilyMembers = 0;
    var fac1ModerateFamilyMemberContacts = [];

    // Factor 2 Deficiency
    var fac2Patients = 0;
    var fac2PatientContacts = [];

    var fac2Caretakers = 0;
    var fac2CaretakerContacts = [];

    var fac2FamilyMembers = 0;
    var fac2FamilyMemberContacts = [];

    // Factor 2 Deficiency Severe
    var fac2SeverePatients = 0;
    var fac2SeverePatientContacts = [];

    var fac2SevereCaretakers = 0;
    var fac2SevereCaretakerContacts = [];

    var fac2SevereFamilyMembers = 0;
    var fac2SevereFamilyMemberContacts = [];

    // Factor 2 Deficiency Mild
    var fac2MildPatients = 0;
    var fac2MildPatientContacts = [];

    var fac2MildCaretakers = 0;
    var fac2MildCaretakerContacts = [];

    var fac2MildFamilyMembers = 0;
    var fac2MildFamilyMemberContacts = [];

    // Factor 2 Deficiency Moderate
    var fac2ModeratePatients = 0;
    var fac2ModeratePatientContacts = [];

    var fac2ModerateCaretakers = 0;
    var fac2ModerateCaretakerContacts = [];

    var fac2ModerateFamilyMembers = 0;
    var fac2ModerateFamilyMemberContacts = [];

    // Factor 5 Deficiency
    var fac5Patients = 0;
    var fac5PatientContacts = [];

    var fac5Caretakers = 0;
    var fac5CaretakerContacts = [];

    var fac5FamilyMembers = 0;
    var fac5FamilyMemberContacts = [];

    // Factor 5 Deficiency Severe
    var fac5SeverePatients = 0;
    var fac5SeverePatientContacts = [];

    var fac5SevereCaretakers = 0;
    var fac5SevereCaretakerContacts = [];

    var fac5SevereFamilyMembers = 0;
    var fac5SevereFamilyMemberContacts = [];

    // Factor 5 Deficiency Mild
    var fac5MildPatients = 0;
    var fac5MildPatientContacts = [];

    var fac5MildCaretakers = 0;
    var fac5MildCaretakerContacts = [];

    var fac5MildFamilyMembers = 0;
    var fac5MildFamilyMemberContacts = [];

    // Factor 5 Deficiency Moderate
    var fac5ModeratePatients = 0;
    var fac5ModeratePatientContacts = [];

    var fac5ModerateCaretakers = 0;
    var fac5ModerateCaretakerContacts = [];

    var fac5ModerateFamilyMembers = 0;
    var fac5ModerateFamilyMemberContacts = [];

    // Factor 7 Deficiency
    var fac7Patients = 0;
    var fac7PatientContacts = [];

    var fac7Caretakers = 0;
    var fac7CaretakerContacts = [];

    var fac7FamilyMembers = 0;
    var fac7FamilyMemberContacts = [];

    // Factor 7 Deficiency Severe
    var fac7SeverePatients = 0;
    var fac7SeverePatientContacts = [];

    var fac7SevereCaretakers = 0;
    var fac7SevereCaretakerContacts = [];

    var fac7SevereFamilyMembers = 0;
    var fac7SevereFamilyMemberContacts = [];

    // Factor 7 Deficiency Mild
    var fac7MildPatients = 0;
    var fac7MildPatientContacts = [];

    var fac7MildCaretakers = 0;
    var fac7MildCaretakerContacts = [];

    var fac7MildFamilyMembers = 0;
    var fac7MildFamilyMemberContacts = [];

    // Factor 7 Deficiency Moderate
    var fac7ModeratePatients = 0;
    var fac7ModeratePatientContacts = [];

    var fac7ModerateCaretakers = 0;
    var fac7ModerateCaretakerContacts = [];

    var fac7ModerateFamilyMembers = 0;
    var fac7ModerateFamilyMemberContacts = [];

    // Factor 10 Deficiency
    var fac10Patients = 0;
    var fac10PatientContacts = [];

    var fac10Caretakers = 0;
    var fac10CaretakerContacts = [];

    var fac10FamilyMembers = 0;
    var fac10FamilyMemberContacts = [];

    // Factor 10 Deficiency Severe
    var fac10SeverePatients = 0;
    var fac10SeverePatientContacts = [];

    var fac10SevereCaretakers = 0;
    var fac10SevereCaretakerContacts = [];

    var fac10SevereFamilyMembers = 0;
    var fac10SevereFamilyMemberContacts = [];

    // Factor 10 Deficiency Mild
    var fac10MildPatients = 0;
    var fac10MildPatientContacts = [];

    var fac10MildCaretakers = 0;
    var fac10MildCaretakerContacts = [];

    var fac10MildFamilyMembers = 0;
    var fac10MildFamilyMemberContacts = [];

    // Factor 10 Deficiency Moderate
    var fac10ModeratePatients = 0;
    var fac10ModeratePatientContacts = [];

    var fac10ModerateCaretakers = 0;
    var fac10ModerateCaretakerContacts = [];

    var fac10ModerateFamilyMembers = 0;
    var fac10ModerateFamilyMemberContacts = [];

    // Factor 12 Deficiency
    var fac12Patients = 0;
    var fac12PatientContacts = [];

    var fac12Caretakers = 0;
    var fac12CaretakerContacts = [];

    var fac12FamilyMembers = 0;
    var fac12FamilyMemberContacts = [];

    // Factor 12 Deficiency Severe
    var fac12SeverePatients = 0;
    var fac12SeverePatientContacts = [];

    var fac12SevereCaretakers = 0;
    var fac12SevereCaretakerContacts = [];

    var fac12SevereFamilyMembers = 0;
    var fac12SevereFamilyMemberContacts = [];

    // Factor 12 Deficiency Mild
    var fac12MildPatients = 0;
    var fac12MildPatientContacts = [];

    var fac12MildCaretakers = 0;
    var fac12MildCaretakerContacts = [];

    var fac12MildFamilyMembers = 0;
    var fac12MildFamilyMemberContacts = [];

    // Factor 12 Deficiency Moderate
    var fac12ModeratePatients = 0;
    var fac12ModeratePatientContacts = [];

    var fac12ModerateCaretakers = 0;
    var fac12ModerateCaretakerContacts = [];

    var fac12ModerateFamilyMembers = 0;
    var fac12ModerateFamilyMemberContacts = [];

    // Factor 13 Deficiency
    var fac13Patients = 0;
    var fac13PatientContacts = [];

    var fac13Caretakers = 0;
    var fac13CaretakerContacts = [];

    var fac13FamilyMembers = 0;
    var fac13FamilyMemberContacts = [];

    // Factor 13 Deficiency Severe
    var fac13SeverePatients = 0;
    var fac13SeverePatientContacts = [];

    var fac13SevereCaretakers = 0;
    var fac13SevereCaretakerContacts = [];

    var fac13SevereFamilyMembers = 0;
    var fac13SevereFamilyMemberContacts = [];

    // Factor 13 Deficiency Mild
    var fac13MildPatients = 0;
    var fac13MildPatientContacts = [];

    var fac13MildCaretakers = 0;
    var fac13MildCaretakerContacts = [];

    var fac13MildFamilyMembers = 0;
    var fac13MildFamilyMemberContacts = [];

    // Factor 13 Deficiency Moderate
    var fac13ModeratePatients = 0;
    var fac13ModeratePatientContacts = [];

    var fac13ModerateCaretakers = 0;
    var fac13ModerateCaretakerContacts = [];

    var fac13ModerateFamilyMembers = 0;
    var fac13ModerateFamilyMemberContacts = [];

    apps.forEach(app => {
        if (app.bleedingDisorder) {
            // Create info object for later storage
            const contactInfo = {
                name: app.firstName + " " + app.lastName,
                email: app.email,
                phone: app.phone,
                state: app.state,
                city: app.city,
                ZIP: app.ZIP,
            }
            // Check Type
            if (app.bleedingDisorderType !== undefined) {
                switch (categorizeBleedingDisorderType(app.bleedingDisorderType)) {
                    // Hemophilia A
                    case hemoASortValue: {
                        // Check for Relationship
                        switch (categorizeBleedingDisorderRelationship(app.bleedingDisorderRelationship)) {
                            // Hemophilia A - Self
                            case selfSortValue: {
                                hemoAPatients++;
                                hemoAPatientContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Hemophilia A - Self - Severe
                                        case severeSortValue: {
                                            hemoASeverePatients++;
                                            hemoASeverePatientContacts.push(contactInfo);
                                            break;
                                        }
                                        // Hemophilia A - Self - Mild
                                        case mildSortValue: {
                                            hemoAMildPatients++;
                                            hemoAMildPatientContacts.push(contactInfo);
                                            break;
                                        }

                                        // Hemophilia A - Self - Moderate
                                        case moderateSortValue: {
                                            hemoAModeratePatients++;
                                            hemoAModeratePatientContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Hemophilia A - Caretaker
                            case careTakerSortValue: {
                                hemoACaretakers++;
                                hemoACaretakerContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Hemophilia A - Caretaker - Severe
                                        case severeSortValue: {
                                            hemoASevereCaretakers++;
                                            hemoASevereCaretakerContacts.push(contactInfo);
                                            break;
                                        }
                                        // Hemophilia A - Caretaker - Mild
                                        case mildSortValue: {
                                            hemoAMildCaretakers++;
                                            hemoAMildCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        // Hemophilia A - Caretaker - Moderate
                                        case moderateSortValue: {
                                            hemoAModerateCaretakers++;
                                            hemoAModerateCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Hemophilia A - Family Member
                            case familyMemberSortValue: {
                                hemoAFamilyMembers++;
                                hemoAFamilyMemberContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Hemophilia A - Family Member - Severe
                                        case severeSortValue: {
                                            hemoASevereFamilyMembers++;
                                            hemoASevereFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        // Hemophilia A - Family Member - Mild
                                        case mildSortValue: {
                                            hemoAMildFamilyMembers++;
                                            hemoAMildFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }

                                        // Hemophilia A - Family Member - Moderate
                                        case moderateSortValue: {
                                            hemoAModerateFamilyMembers++;
                                            hemoAModerateFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                        }
                        break;
                    }

                    case hemoBSortValue: {
                        // Check for Relationship
                        switch (categorizeBleedingDisorderRelationship(app.bleedingDisorderRelationship)) {
                            // Hemophilia B - Self
                            case selfSortValue: {
                                hemoBPatients++;
                                hemoBPatientContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Hemophilia B - Self - Severe
                                        case severeSortValue: {
                                            hemoBSeverePatients++;
                                            hemoBSeverePatientContacts.push(contactInfo);
                                            break;
                                        }
                                        // Hemophilia B - Self - Mild
                                        case mildSortValue: {
                                            hemoBMildPatients++;
                                            hemoBMildPatientContacts.push(contactInfo);
                                            break;
                                        }

                                        // Hemophilia B - Self - Moderate
                                        case moderateSortValue: {
                                            hemoBModeratePatients++;
                                            hemoBModeratePatientContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Hemophilia B - Caretaker
                            case careTakerSortValue: {
                                hemoBCaretakers++;
                                hemoBCaretakerContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Hemophilia B - Caretaker - Severe
                                        case severeSortValue: {
                                            hemoBSevereCaretakers++;
                                            hemoBSevereCaretakerContacts.push(contactInfo);
                                            break;
                                        }
                                        // Hemophilia B - Caretaker - Mild
                                        case mildSortValue: {
                                            hemoBMildCaretakers++;
                                            hemoBMildCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        // Hemophilia B - Caretaker - Moderate
                                        case moderateSortValue: {
                                            hemoBModerateCaretakers++;
                                            hemoBModerateCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Hemophilia B - Family Member
                            case familyMemberSortValue: {
                                hemoBFamilyMembers++;
                                hemoBFamilyMemberContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Hemophilia B - Family Member - Severe
                                        case severeSortValue: {
                                            hemoBSevereFamilyMembers++;
                                            hemoBSevereFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        // Hemophilia B - Family Member - Mild
                                        case mildSortValue: {
                                            hemoBMildFamilyMembers++;
                                            hemoBMildFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }

                                        // Hemophilia B - Family Member - Moderate
                                        case moderateSortValue: {
                                            hemoBModerateFamilyMembers++;
                                            hemoBModerateFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                        }
                        break;
                    }

                    case hemoCSortValue: {
                        // Check for Relationship
                        switch (categorizeBleedingDisorderRelationship(app.bleedingDisorderRelationship)) {
                            // Hemophilia C - Self
                            case selfSortValue: {
                                hemoCPatients++;
                                hemoCPatientContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Hemophilia C - Self - Severe
                                        case severeSortValue: {
                                            hemoCSeverePatients++;
                                            hemoCSeverePatientContacts.push(contactInfo);
                                            break;
                                        }
                                        // Hemophilia C - Self - Mild
                                        case mildSortValue: {
                                            hemoCMildPatients++;
                                            hemoCMildPatientContacts.push(contactInfo);
                                            break;
                                        }

                                        // Hemophilia C - Self - Moderate
                                        case moderateSortValue: {
                                            hemoCModeratePatients++;
                                            hemoCModeratePatientContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Hemophilia C - Caretaker
                            case careTakerSortValue: {
                                hemoCCaretakers++;
                                hemoCCaretakerContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Hemophilia C - Caretaker - Severe
                                        case severeSortValue: {
                                            hemoCSevereCaretakers++;
                                            hemoCSevereCaretakerContacts.push(contactInfo);
                                            break;
                                        }
                                        // Hemophilia C - Caretaker - Mild
                                        case mildSortValue: {
                                            hemoCMildCaretakers++;
                                            hemoCMildCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        // Hemophilia C - Caretaker - Moderate
                                        case moderateSortValue: {
                                            hemoCModerateCaretakers++;
                                            hemoCModerateCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Hemophilia C - Family Member
                            case familyMemberSortValue: {
                                hemoCFamilyMembers++;
                                hemoCFamilyMemberContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Hemophilia C - Family Member - Severe
                                        case severeSortValue: {
                                            hemoCSevereFamilyMembers++;
                                            hemoCSevereFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        // Hemophilia C - Family Member - Mild
                                        case mildSortValue: {
                                            hemoCMildFamilyMembers++;
                                            hemoCMildFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }

                                        // Hemophilia C - Family Member - Moderate
                                        case moderateSortValue: {
                                            hemoCModerateFamilyMembers++;
                                            hemoCModerateFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                        }
                        break;
                    }

                    case VWDSortValue: {
                        // Check for Relationship
                        switch (categorizeBleedingDisorderRelationship(app.bleedingDisorderRelationship)) {
                            // VWD - Self
                            case selfSortValue: {
                                VWDPatients++;
                                VWDPatientContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // VWD - Self - Severe
                                        case severeSortValue: {
                                            VWDSeverePatients++;
                                            VWDSeverePatientContacts.push(contactInfo);
                                            break;
                                        }
                                        // VWD - Self - Mild
                                        case mildSortValue: {
                                            VWDMildPatients++;
                                            VWDMildPatientContacts.push(contactInfo);
                                            break;
                                        }

                                        // VWD - Self - Moderate
                                        case moderateSortValue: {
                                            VWDModeratePatients++;
                                            VWDModeratePatientContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // VWD - Caretaker
                            case careTakerSortValue: {
                                VWDCaretakers++;
                                VWDCaretakerContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // VWD - Caretaker - Severe
                                        case severeSortValue: {
                                            VWDSevereCaretakers++;
                                            VWDSevereCaretakerContacts.push(contactInfo);
                                            break;
                                        }
                                        // VWD - Caretaker - Mild
                                        case mildSortValue: {
                                            VWDMildCaretakers++;
                                            VWDMildCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        // VWD - Caretaker - Moderate
                                        case moderateSortValue: {
                                            VWDModerateCaretakers++;
                                            VWDModerateCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // VWD - Family Member
                            case familyMemberSortValue: {
                                VWDFamilyMembers++;
                                VWDFamilyMemberContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // VWD - Family Member - Severe
                                        case severeSortValue: {
                                            VWDSevereFamilyMembers++;
                                            VWDSevereFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        // VWD - Family Member - Mild
                                        case mildSortValue: {
                                            VWDMildFamilyMembers++;
                                            VWDMildFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }

                                        // VWD - Family Member - Moderate
                                        case moderateSortValue: {
                                            VWDModerateFamilyMembers++;
                                            VWDModerateFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                        }
                        break;
                    }

                    case fac1SortValue: {
                        // Check for Relationship
                        switch (categorizeBleedingDisorderRelationship(app.bleedingDisorderRelationship)) {
                            // Factor 1 Deficiency - Self
                            case selfSortValue: {
                                fac1Patients++;
                                fac1PatientContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 1 Deficiency - Self - Severe
                                        case severeSortValue: {
                                            fac1SeverePatients++;
                                            fac1SeverePatientContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 1 Deficiency - Self - Mild
                                        case mildSortValue: {
                                            fac1MildPatients++;
                                            fac1MildPatientContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 1 Deficiency - Self - Moderate
                                        case moderateSortValue: {
                                            fac1ModeratePatients++;
                                            fac1ModeratePatientContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Factor 1 Deficiency - Caretaker
                            case careTakerSortValue: {
                                fac1Caretakers++;
                                fac1CaretakerContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 1 Deficiency - Caretaker - Severe
                                        case severeSortValue: {
                                            fac1SevereCaretakers++;
                                            fac1SevereCaretakerContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 1 Deficiency- Caretaker - Mild
                                        case mildSortValue: {
                                            fac1MildCaretakers++;
                                            fac1MildCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 1 Deficiency - Caretaker - Moderate
                                        case moderateSortValue: {
                                            fac1ModerateCaretakers++;
                                            fac1ModerateCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Factor 1 Deficiency - Family Member
                            case familyMemberSortValue: {
                                fac1FamilyMembers++;
                                fac1FamilyMemberContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 1 Deficiency - Family Member - Severe
                                        case severeSortValue: {
                                            fac1SevereFamilyMembers++;
                                            fac1SevereFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 1 Deficiency - Family Member - Mild
                                        case mildSortValue: {
                                            fac1MildFamilyMembers++;
                                            fac1MildFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 1 Deficiency - Family Member - Moderate
                                        case moderateSortValue: {
                                            fac1ModerateFamilyMembers++;
                                            fac1ModerateFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                        }
                        break;
                    }

                    case fac2SortValue: {
                        // Check for Relationship
                        switch (categorizeBleedingDisorderRelationship(app.bleedingDisorderRelationship)) {
                            // Factor 2 Deficiency - Self
                            case selfSortValue: {
                                fac2Patients++;
                                fac2PatientContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 2 Deficiency - Self - Severe
                                        case severeSortValue: {
                                            fac2SeverePatients++;
                                            fac2SeverePatientContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 2 Deficiency - Self - Mild
                                        case mildSortValue: {
                                            fac2MildPatients++;
                                            fac2MildPatientContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 2 Deficiency - Self - Moderate
                                        case moderateSortValue: {
                                            fac2ModeratePatients++;
                                            fac2ModeratePatientContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Factor 2 Deficiency - Caretaker
                            case careTakerSortValue: {
                                fac2Caretakers++;
                                fac2CaretakerContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 2 Deficiency - Caretaker - Severe
                                        case severeSortValue: {
                                            fac2SevereCaretakers++;
                                            fac2SevereCaretakerContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 2 Deficiency- Caretaker - Mild
                                        case mildSortValue: {
                                            fac2MildCaretakers++;
                                            fac2MildCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 2 Deficiency - Caretaker - Moderate
                                        case moderateSortValue: {
                                            fac2ModerateCaretakers++;
                                            fac2ModerateCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Factor 2 Deficiency - Family Member
                            case familyMemberSortValue: {
                                fac2FamilyMembers++;
                                fac2FamilyMemberContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 2 Deficiency - Family Member - Severe
                                        case severeSortValue: {
                                            fac2SevereFamilyMembers++;
                                            fac2SevereFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 2 Deficiency - Family Member - Mild
                                        case mildSortValue: {
                                            fac2MildFamilyMembers++;
                                            fac2MildFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 2 Deficiency - Family Member - Moderate
                                        case moderateSortValue: {
                                            fac2ModerateFamilyMembers++;
                                            fac2ModerateFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                        }
                        break;
                    }

                    case fac5SortValue: {
                        // Check for Relationship
                        switch (categorizeBleedingDisorderRelationship(app.bleedingDisorderRelationship)) {
                            // Factor 5 Deficiency - Self
                            case selfSortValue: {
                                fac5Patients++;
                                fac5PatientContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 5 Deficiency - Self - Severe
                                        case severeSortValue: {
                                            fac5SeverePatients++;
                                            fac5SeverePatientContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 5 Deficiency - Self - Mild
                                        case mildSortValue: {
                                            fac5MildPatients++;
                                            fac5MildPatientContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 5 Deficiency - Self - Moderate
                                        case moderateSortValue: {
                                            fac5ModeratePatients++;
                                            fac5ModeratePatientContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Factor 5 Deficiency - Caretaker
                            case careTakerSortValue: {
                                fac5Caretakers++;
                                fac5CaretakerContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 5 Deficiency - Caretaker - Severe
                                        case severeSortValue: {
                                            fac5SevereCaretakers++;
                                            fac5SevereCaretakerContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 5 Deficiency- Caretaker - Mild
                                        case mildSortValue: {
                                            fac5MildCaretakers++;
                                            fac5MildCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 5 Deficiency - Caretaker - Moderate
                                        case moderateSortValue: {
                                            fac5ModerateCaretakers++;
                                            fac5ModerateCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Factor 5 Deficiency - Family Member
                            case familyMemberSortValue: {
                                fac5FamilyMembers++;
                                fac5FamilyMemberContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 5 Deficiency - Family Member - Severe
                                        case severeSortValue: {
                                            fac5SevereFamilyMembers++;
                                            fac5SevereFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 5 Deficiency - Family Member - Mild
                                        case mildSortValue: {
                                            fac5MildFamilyMembers++;
                                            fac5MildFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 5 Deficiency - Family Member - Moderate
                                        case moderateSortValue: {
                                            fac5ModerateFamilyMembers++;
                                            fac5ModerateFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                        }
                        break;
                    }

                    case fac7SortValue: {
                        // Check for Relationship
                        switch (categorizeBleedingDisorderRelationship(app.bleedingDisorderRelationship)) {
                            // Factor 7 Deficiency - Self
                            case selfSortValue: {
                                fac7Patients++;
                                fac7PatientContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 7 Deficiency - Self - Severe
                                        case severeSortValue: {
                                            fac7SeverePatients++;
                                            fac7SeverePatientContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 7 Deficiency - Self - Mild
                                        case mildSortValue: {
                                            fac7MildPatients++;
                                            fac7MildPatientContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 7 Deficiency - Self - Moderate
                                        case moderateSortValue: {
                                            fac7ModeratePatients++;
                                            fac7ModeratePatientContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Factor 7 Deficiency - Caretaker
                            case careTakerSortValue: {
                                fac7Caretakers++;
                                fac7CaretakerContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 7 Deficiency - Caretaker - Severe
                                        case severeSortValue: {
                                            fac7SevereCaretakers++;
                                            fac7SevereCaretakerContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 7 Deficiency- Caretaker - Mild
                                        case mildSortValue: {
                                            fac7MildCaretakers++;
                                            fac7MildCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 7 Deficiency - Caretaker - Moderate
                                        case moderateSortValue: {
                                            fac7ModerateCaretakers++;
                                            fac7ModerateCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Factor 7 Deficiency - Family Member
                            case familyMemberSortValue: {
                                fac7FamilyMembers++;
                                fac7FamilyMemberContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 7 Deficiency - Family Member - Severe
                                        case severeSortValue: {
                                            fac7SevereFamilyMembers++;
                                            fac7SevereFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 7 Deficiency - Family Member - Mild
                                        case mildSortValue: {
                                            fac7MildFamilyMembers++;
                                            fac7MildFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 7 Deficiency - Family Member - Moderate
                                        case moderateSortValue: {
                                            fac7ModerateFamilyMembers++;
                                            fac7ModerateFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                        }
                        break;
                    }

                    case fac10SortValue: {
                        // Check for Relationship
                        switch (categorizeBleedingDisorderRelationship(app.bleedingDisorderRelationship)) {
                            // Factor 10 Deficiency - Self
                            case selfSortValue: {
                                fac10Patients++;
                                fac10PatientContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 10 Deficiency - Self - Severe
                                        case severeSortValue: {
                                            fac10SeverePatients++;
                                            fac10SeverePatientContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 10 Deficiency - Self - Mild
                                        case mildSortValue: {
                                            fac10MildPatients++;
                                            fac10MildPatientContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 10 Deficiency - Self - Moderate
                                        case moderateSortValue: {
                                            fac10ModeratePatients++;
                                            fac10ModeratePatientContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Factor 10 Deficiency - Caretaker
                            case careTakerSortValue: {
                                fac10Caretakers++;
                                fac10CaretakerContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 10 Deficiency - Caretaker - Severe
                                        case severeSortValue: {
                                            fac10SevereCaretakers++;
                                            fac10SevereCaretakerContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 10 Deficiency- Caretaker - Mild
                                        case mildSortValue: {
                                            fac10MildCaretakers++;
                                            fac10MildCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 10 Deficiency - Caretaker - Moderate
                                        case moderateSortValue: {
                                            fac10ModerateCaretakers++;
                                            fac10ModerateCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Factor 10 Deficiency - Family Member
                            case familyMemberSortValue: {
                                fac10FamilyMembers++;
                                fac10FamilyMemberContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 10 Deficiency - Family Member - Severe
                                        case severeSortValue: {
                                            fac10SevereFamilyMembers++;
                                            fac10SevereFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 10 Deficiency - Family Member - Mild
                                        case mildSortValue: {
                                            fac10MildFamilyMembers++;
                                            fac10MildFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 10 Deficiency - Family Member - Moderate
                                        case moderateSortValue: {
                                            fac10ModerateFamilyMembers++;
                                            fac10ModerateFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                        }
                        break;
                    }

                    case fac12SortValue: {
                        // Check for Relationship
                        switch (categorizeBleedingDisorderRelationship(app.bleedingDisorderRelationship)) {
                            // Factor 12 Deficiency - Self
                            case selfSortValue: {
                                fac12Patients++;
                                fac12PatientContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 12 Deficiency - Self - Severe
                                        case severeSortValue: {
                                            fac12SeverePatients++;
                                            fac12SeverePatientContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 12 Deficiency - Self - Mild
                                        case mildSortValue: {
                                            fac12MildPatients++;
                                            fac12MildPatientContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 12 Deficiency - Self - Moderate
                                        case moderateSortValue: {
                                            fac12ModeratePatients++;
                                            fac12ModeratePatientContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Factor 12 Deficiency - Caretaker
                            case careTakerSortValue: {
                                fac12Caretakers++;
                                fac12CaretakerContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 12 Deficiency - Caretaker - Severe
                                        case severeSortValue: {
                                            fac12SevereCaretakers++;
                                            fac12SevereCaretakerContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 12 Deficiency- Caretaker - Mild
                                        case mildSortValue: {
                                            fac12MildCaretakers++;
                                            fac12MildCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 12 Deficiency - Caretaker - Moderate
                                        case moderateSortValue: {
                                            fac12ModerateCaretakers++;
                                            fac12ModerateCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Factor 12 Deficiency - Family Member
                            case familyMemberSortValue: {
                                fac12FamilyMembers++;
                                fac12FamilyMemberContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 12 Deficiency - Family Member - Severe
                                        case severeSortValue: {
                                            fac12SevereFamilyMembers++;
                                            fac12SevereFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 12 Deficiency - Family Member - Mild
                                        case mildSortValue: {
                                            fac12MildFamilyMembers++;
                                            fac12MildFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 12 Deficiency - Family Member - Moderate
                                        case moderateSortValue: {
                                            fac12ModerateFamilyMembers++;
                                            fac12ModerateFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                        }
                        break;
                    }

                    case fac13SortValue: {
                        // Check for Relationship
                        switch (categorizeBleedingDisorderRelationship(app.bleedingDisorderRelationship)) {
                            // Factor 13 Deficiency - Self
                            case selfSortValue: {
                                fac13Patients++;
                                fac13PatientContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 13 Deficiency - Self - Severe
                                        case severeSortValue: {
                                            fac13SeverePatients++;
                                            fac13SeverePatientContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 13 Deficiency - Self - Mild
                                        case mildSortValue: {
                                            fac13MildPatients++;
                                            fac13MildPatientContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 13 Deficiency - Self - Moderate
                                        case moderateSortValue: {
                                            fac13ModeratePatients++;
                                            fac13ModeratePatientContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Factor 13 Deficiency - Caretaker
                            case careTakerSortValue: {
                                fac13Caretakers++;
                                fac13CaretakerContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 13 Deficiency - Caretaker - Severe
                                        case severeSortValue: {
                                            fac13SevereCaretakers++;
                                            fac13SevereCaretakerContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 13 Deficiency- Caretaker - Mild
                                        case mildSortValue: {
                                            fac13MildCaretakers++;
                                            fac13MildCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 13 Deficiency - Caretaker - Moderate
                                        case moderateSortValue: {
                                            fac13ModerateCaretakers++;
                                            fac13ModerateCaretakerContacts.push(contactInfo);
                                            break;
                                        }

                                        default:
                                            break;
                                    }
                                }
                                break;
                            }
                            // Factor 13 Deficiency - Family Member
                            case familyMemberSortValue: {
                                fac13FamilyMembers++;
                                fac13FamilyMemberContacts.push(contactInfo);
                                if (app.bleedingDisorderSeverity) {
                                    switch (categorizeBleedingDisorderSeverity(app.bleedingDisorderSeverity)) {
                                        // Factor 13 Deficiency - Family Member - Severe
                                        case severeSortValue: {
                                            fac13SevereFamilyMembers++;
                                            fac13SevereFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        // Factor 13 Deficiency - Family Member - Mild
                                        case mildSortValue: {
                                            fac13MildFamilyMembers++;
                                            fac13MildFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }

                                        // Factor 13 Deficiency - Family Member - Moderate
                                        case moderateSortValue: {
                                            fac13ModerateFamilyMembers++;
                                            fac13ModerateFamilyMemberContacts.push(contactInfo);
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                }
                                break;
                            }

                            default:
                                break;
                        }
                        break;
                    }
                }
            }
        }
    });

    // Compile all data, initalize using value names
    const BleedingDisorderStatistics = {
        // Hemophilia A
        hemoAPatients,
        hemoAPatientContacts,
        hemoACaretakers,
        hemoACaretakerContacts,
        hemoAFamilyMembers,
        hemoAFamilyMemberContacts,

        // Hemophilia A Severe
        hemoASeverePatients,
        hemoASeverePatientContacts,
        hemoASevereCaretakers,
        hemoASevereCaretakerContacts,
        hemoASevereFamilyMembers,
        hemoASevereFamilyMemberContacts,

        // Hemophilia A Mild
        hemoAMildPatients,
        hemoAMildPatientContacts,
        hemoAMildCaretakers,
        hemoAMildCaretakerContacts,
        hemoAMildFamilyMembers,
        hemoAMildFamilyMemberContacts,

        // Hemophilia A Moderate
        hemoAModeratePatients,
        hemoAModeratePatientContacts,
        hemoAModerateCaretakers,
        hemoAModerateCaretakerContacts,
        hemoAModerateFamilyMembers,
        hemoAModerateFamilyMemberContacts,

        // Hemophilia B
        hemoBPatients,
        hemoBPatientContacts,
        hemoBCaretakers,
        hemoBCaretakerContacts,
        hemoBFamilyMembers,
        hemoBFamilyMemberContacts,

        // Hemophilia B Severe
        hemoBSeverePatients,
        hemoBSeverePatientContacts,
        hemoBSevereCaretakers,
        hemoBSevereCaretakerContacts,
        hemoBSevereFamilyMembers,
        hemoBSevereFamilyMemberContacts,

        // Hemophilia B Mild
        hemoBMildPatients,
        hemoBMildPatientContacts,
        hemoBMildCaretakers,
        hemoBMildCaretakerContacts,
        hemoBMildFamilyMembers,
        hemoBMildFamilyMemberContacts,

        // Hemophilia B Moderate
        hemoBModeratePatients,
        hemoBModeratePatientContacts,
        hemoBModerateCaretakers,
        hemoBModerateCaretakerContacts,
        hemoBModerateFamilyMembers,
        hemoBModerateFamilyMemberContacts,

        // Hemophilia C
        hemoCPatients,
        hemoCPatientContacts,
        hemoCCaretakers,
        hemoCCaretakerContacts,
        hemoCFamilyMembers,
        hemoCFamilyMemberContacts,

        // Hemophilia C Severe
        hemoCSeverePatients,
        hemoCSeverePatientContacts,
        hemoCSevereCaretakers,
        hemoCSevereCaretakerContacts,
        hemoCSevereFamilyMembers,
        hemoCSevereFamilyMemberContacts,

        // Hemophilia C Mild
        hemoCMildPatients,
        hemoCMildPatientContacts,
        hemoCMildCaretakers,
        hemoCMildCaretakerContacts,
        hemoCMildFamilyMembers,
        hemoCMildFamilyMemberContacts,

        // Hemophilia C Moderate
        hemoCModeratePatients,
        hemoCModeratePatientContacts,
        hemoCModerateCaretakers,
        hemoCModerateCaretakerContacts,
        hemoCModerateFamilyMembers,
        hemoCModerateFamilyMemberContacts,

        // Von Willebrand Disease (VWD)
        VWDPatients,
        VWDPatientContacts,
        VWDCaretakers,
        VWDCaretakerContacts,
        VWDFamilyMembers,
        VWDFamilyMemberContacts,

        // VWD Severe
        VWDSeverePatients,
        VWDSeverePatientContacts,
        VWDSevereCaretakers,
        VWDSevereCaretakerContacts,
        VWDSevereFamilyMembers,
        VWDSevereFamilyMemberContacts,

        // VWD Mild
        VWDMildPatients,
        VWDMildPatientContacts,
        VWDMildCaretakers,
        VWDMildCaretakerContacts,
        VWDMildFamilyMembers,
        VWDMildFamilyMemberContacts,

        // VWD Moderate
        VWDModeratePatients,
        VWDModeratePatientContacts,
        VWDModerateCaretakers,
        VWDModerateCaretakerContacts,
        VWDModerateFamilyMembers,
        VWDModerateFamilyMemberContacts,

        // Factor Deficiencies (1, 2, 5, 7, 10, 12, 13)

        // Factor 1 Deficiency
        fac1Patients,
        fac1PatientContacts,
        fac1Caretakers,
        fac1CaretakerContacts,
        fac1FamilyMembers,
        fac1FamilyMemberContacts,

        // Factor 1 Deficiency Severe
        fac1SeverePatients,
        fac1SeverePatientContacts,
        fac1SevereCaretakers,
        fac1SevereCaretakerContacts,
        fac1SevereFamilyMembers,
        fac1SevereFamilyMemberContacts,

        // Factor 1 Deficiency Mild
        fac1MildPatients,
        fac1MildPatientContacts,
        fac1MildCaretakers,
        fac1MildCaretakerContacts,
        fac1MildFamilyMembers,
        fac1MildFamilyMemberContacts,

        // Factor 1 Deficiency Moderate
        fac1ModeratePatients,
        fac1ModeratePatientContacts,
        fac1ModerateCaretakers,
        fac1ModerateCaretakerContacts,
        fac1ModerateFamilyMembers,
        fac1ModerateFamilyMemberContacts,

        // Factor 2 Deficiency
        fac2Patients,
        fac2PatientContacts,
        fac2Caretakers,
        fac2CaretakerContacts,
        fac2FamilyMembers,
        fac2FamilyMemberContacts,

        // Factor 2 Deficiency Severe
        fac2SeverePatients,
        fac2SeverePatientContacts,
        fac2SevereCaretakers,
        fac2SevereCaretakerContacts,
        fac2SevereFamilyMembers,
        fac2SevereFamilyMemberContacts,

        // Factor 2 Deficiency Mild
        fac2MildPatients,
        fac2MildPatientContacts,
        fac2MildCaretakers,
        fac2MildCaretakerContacts,
        fac2MildFamilyMembers,
        fac2MildFamilyMemberContacts,

        // Factor 2 Deficiency Moderate
        fac2ModeratePatients,
        fac2ModeratePatientContacts,
        fac2ModerateCaretakers,
        fac2ModerateCaretakerContacts,
        fac2ModerateFamilyMembers,
        fac2ModerateFamilyMemberContacts,

        // Factor 5 Deficiency
        fac5Patients,
        fac5PatientContacts,
        fac5Caretakers,
        fac5CaretakerContacts,
        fac5FamilyMembers,
        fac5FamilyMemberContacts,

        // Factor 5 Deficiency Severe
        fac5SeverePatients,
        fac5SeverePatientContacts,
        fac5SevereCaretakers,
        fac5SevereCaretakerContacts,
        fac5SevereFamilyMembers,
        fac5SevereFamilyMemberContacts,

        // Factor 5 Deficiency Mild
        fac5MildPatients,
        fac5MildPatientContacts,
        fac5MildCaretakers,
        fac5MildCaretakerContacts,
        fac5MildFamilyMembers,
        fac5MildFamilyMemberContacts,

        // Factor 5 Deficiency Moderate
        fac5ModeratePatients,
        fac5ModeratePatientContacts,
        fac5ModerateCaretakers,
        fac5ModerateCaretakerContacts,
        fac5ModerateFamilyMembers,
        fac5ModerateFamilyMemberContacts,

        // Factor 7 Deficiency
        fac7Patients,
        fac7PatientContacts,
        fac7Caretakers,
        fac7CaretakerContacts,
        fac7FamilyMembers,
        fac7FamilyMemberContacts,

        // Factor 7 Deficiency Severe
        fac7SeverePatients,
        fac7SeverePatientContacts,
        fac7SevereCaretakers,
        fac7SevereCaretakerContacts,
        fac7SevereFamilyMembers,
        fac7SevereFamilyMemberContacts,

        // Factor  Deficiency Mild
        fac7MildPatients,
        fac7MildPatientContacts,
        fac7MildCaretakers,
        fac7MildCaretakerContacts,
        fac7MildFamilyMembers,
        fac7MildFamilyMemberContacts,

        // Factor 7 Deficiency Moderate
        fac7ModeratePatients,
        fac7ModeratePatientContacts,
        fac7ModerateCaretakers,
        fac7ModerateCaretakerContacts,
        fac7ModerateFamilyMembers,
        fac7ModerateFamilyMemberContacts,

        // Factor 10 Deficiency
        fac10Patients,
        fac10PatientContacts,
        fac10Caretakers,
        fac10CaretakerContacts,
        fac10FamilyMembers,
        fac10FamilyMemberContacts,

        // Factor 10 Deficiency Severe
        fac10SeverePatients,
        fac10SeverePatientContacts,
        fac10SevereCaretakers,
        fac10SevereCaretakerContacts,
        fac10SevereFamilyMembers,
        fac10SevereFamilyMemberContacts,

        // Factor 10 Deficiency Mild
        fac10MildPatients,
        fac10MildPatientContacts,
        fac10MildCaretakers,
        fac10MildCaretakerContacts,
        fac10MildFamilyMembers,
        fac10MildFamilyMemberContacts,

        // Factor 10 Deficiency Moderate
        fac10ModeratePatients,
        fac10ModeratePatientContacts,
        fac10ModerateCaretakers,
        fac10ModerateCaretakerContacts,
        fac10ModerateFamilyMembers,
        fac10ModerateFamilyMemberContacts,

        // Factor 12 Deficiency
        fac12Patients,
        fac12PatientContacts,
        fac12Caretakers,
        fac12CaretakerContacts,
        fac12FamilyMembers,
        fac12FamilyMemberContacts,

        // Factor 12 Deficiency Severe
        fac12SeverePatients,
        fac12SeverePatientContacts,
        fac12SevereCaretakers,
        fac12SevereCaretakerContacts,
        fac12SevereFamilyMembers,
        fac12SevereFamilyMemberContacts,

        // Factor 12 Deficiency Mild
        fac12MildPatients,
        fac12MildPatientContacts,
        fac12MildCaretakers,
        fac12MildCaretakerContacts,
        fac12MildFamilyMembers,
        fac12MildFamilyMemberContacts,

        // Factor 12 Deficiency Moderate
        fac12ModeratePatients,
        fac12ModeratePatientContacts,
        fac12ModerateCaretakers,
        fac12ModerateCaretakerContacts,
        fac12ModerateFamilyMembers,
        fac12ModerateFamilyMemberContacts,

        // Factor 13 Deficiency
        fac13Patients,
        fac13PatientContacts,
        fac13Caretakers,
        fac13CaretakerContacts,
        fac13FamilyMembers,
        fac13FamilyMemberContacts,

        // Factor 13 Deficiency Severe
        fac13SeverePatients,
        fac13SeverePatientContacts,
        fac13SevereCaretakers,
        fac13SevereCaretakerContacts,
        fac13SevereFamilyMembers,
        fac13SevereFamilyMemberContacts,

        // Factor 13 Deficiency Mild
        fac13MildPatients,
        fac13MildPatientContacts,
        fac13MildCaretakers,
        fac13MildCaretakerContacts,
        fac13MildFamilyMembers,
        fac13MildFamilyMemberContacts,

        // Factor 13 Deficiency Moderate
        fac13ModeratePatients,
        fac13ModeratePatientContacts,
        fac13ModerateCaretakers,
        fac13ModerateCaretakerContacts,
        fac13ModerateFamilyMembers,
        fac13ModerateFamilyMemberContacts,
    };

    return BleedingDisorderStatistics;
}